import React, {Component} from "react";
import FranchiseEmptyImage from '../../core/images/empty-state/No_frachise_found.svg';

export default class MyFranchise extends Component {
    constructor(props) {
        super(props);

        this.state = {
            franchiseList: [],
            page: 1,
            stopLoadMore: false,
            showEmptyStats: false,
            scrollLoader: true,
            loading: true,
        }
    }

    componentDidMount() {
        let params = {
            page: this.state.page,
        }
        this.getMyFranchiseList(params)
    }

    getMyFranchiseList = (params) => {
        this.props.getMyFranchiseList(params)
            .then((res) => {
                const sources = res.data;
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 1000);
                if (sources.status === true) {
                    let oldData = this.state.franchiseList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        franchiseList: newData,
                    });
                    if (sources.data.data === "") {
                        this.setState({
                            showEmptyStats: true,
                        });
                    }
                    if (sources.data.current_page === sources.data.last_page) {
                        this.setState({
                            stopLoadMore: true,
                        })
                    } else {
                        this.setState({
                            stopLoadMore: false,
                        })
                    }
                } else {

                }
            }).catch((error) => {
            //const sources = error.response;
        })
    }

    handleScroll = () => {
        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight) - (allBooking.scrollTop + 590);

        if (parseInt(allBookingHeight) === 0) {
            if (this.state.stopLoadMore === false) {
                this.NextPage()
            }
        }
    }

    NextPage = () => {
        this.setState({
            scrollLoader: false
        })
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1,
            scrollLoader: true
        }, () => {
            let params = {
                page: this.state.page,
            }
            this.getMyFranchiseList(params)
        })

    }

    render() {
        return (
            <React.Fragment>
                <div style={{height: '100%', maxHeight: '590px', overflowY: 'scroll'}} id={"allBooking"}
                     onScroll={this.handleScroll} className="table-responsive">
                    <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Property Name</th>
                            <th>Category Name</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.loading ?
                                <tr>
                                    <td>
                                        <div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div>
                                    </td>
                                    <td>
                                        <div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div>
                                    </td>
                                    <td>
                                        <div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div>
                                    </td>
                                    <td>
                                        <div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div>
                                    </td>
                                    <td>
                                        <div className="bp3-skeleton" style={{width: "100%", height: "30px"}}></div>
                                    </td>
                                </tr>
                                :
                                this.state.franchiseList.length ?
                                    this.state.franchiseList.map((franchise, key) => {
                                        return <tr key={key}>
                                            <td>{franchise.franchise_id}</td>
                                            <td>{franchise.brand_name}</td>
                                            <td>{franchise.category_name}</td>
                                            <td>₹ {franchise.min_investment} - {franchise.max_investment}</td>
                                            <td></td>
                                        </tr>
                                    })
                                    :

                                    <tr>
                                        <td colSpan="6" align="center">
                                            <div className="text-center">
                                                <img src={FranchiseEmptyImage} alt=""/>
                                                <p className="mt-3">Sorry! there is no franchise.</p>
                                            </div>
                                        </td>
                                    </tr>

                        }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}
