import React, {Component} from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';

export default class TopBar extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row top-bar mr-xl-0 pr-xl-0">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12 d-flex align-items-center pl-0">
                    <span className="back-icon"><KeyboardArrowLeftRounded
                        onClick={() => {
                            this.props.back.goBack();
                        }}/>
                    </span>
                        <h1 className="title m-0">{this.props.title}</h1>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}