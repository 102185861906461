const MaxArea = {
    '500': '500',
    '1000': '1000',
    '1500': '1500',
    '2000': '2000',
    '2500': '2500',
    '3000': '3000',
    '5000': '5000'
};

export default Object.keys(MaxArea).map(value => ({
    value,
    label: MaxArea[value]
}));
