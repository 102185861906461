import React, {Component} from "react";
import TopBar from "../core/components/TopBar";
// import InvestorRegisterContainer from "./container/InvestorRegisterContainer";
import WizardIndex from './components/WizardIndex';
import {Auth} from "gg-react-utilities";

export default class InvestorRegister extends Component {

    componentWillMount() {
        if (!Auth.isLoggedIn()) {
            Auth.login(true)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar back={this.props.history} title="Investor Register"/>
                    <WizardIndex
                        {...this.props}
                    />
                </div>
            </React.Fragment>
        )
    }

}