const BudgetList = {
    'Rs. 10 K - 50 K': '₹ 10 K - 50 K',
    'Rs. 50 K - 2lac': '₹ 50 K - 2lac',
    'Rs. 2lac - 5lac': '₹ 2lac - 5lac',
    'Rs. 5lac - 10lac': '₹ 5lac - 10lac',
    'Rs. 10lac - 20lac': '₹ 10lac - 20lac',
    'Rs. 20lac - 30lac': '₹ 20lac - 30lac',
    'Rs. 30lac - 50lac': '₹ 30lac - 50lac',
    'Rs. 50lac - 1 Cr': '₹ 50lac - 1 Cr',
    'Rs. 1 Cr. - 2 Cr': '₹ 1 Cr. - 2 Cr',
    'Rs. 2 Cr. - 5 Cr': '₹ 2 Cr. - 5 Cr',
    'Rs. 5 Cr. above': '₹ 5 Cr. above',
};

export default Object.keys(BudgetList).map(value => ({
    value,
    label: BudgetList[value]
}));
