import React, {Component} from 'react';
import ChanelType from '../staticData/ChanelType';
import Investment from '../staticData/Investment';
import AreaType from '../staticData/AreaType';
import {Button, Intent} from "@blueprintjs/core";
import {Field} from "react-final-form";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";

class TradePartners extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 mb-3">
                        {
                            this.props.lptCount > 0 ?
                                <h4 className="tp-title">
                                    <span className="float-left">{this.props.lptCount + 1}. Trade Partner</span>
                                    <span className="float-right">
                                        <Button
                                            onClick={() => this.props.lptFields.remove(this.props.lptCount)}
                                            intent={Intent.DANGER}
                                            text="Remove Partner"
                                            icon="trash"
                                            small={true}
                                        />
                                    </span>
                                </h4>
                                : <h4 className="tp-title">
                                    <span className="float-right">
                                        <Button
                                            onClick={() => this.props.lptFields.push(undefined)}
                                            intent={Intent.PRIMARY}
                                            text="Add Partner"
                                            icon="plus"
                                            small={true}
                                        />
                                    </span>
                                </h4>
                        }
                    </div>
                    <div className="col-md-6 mb-3">
                        <SelectAdapter
                            name={`${this.props.lptName}.chanel_type`}
                            options={ChanelType}
                            placeholder="Types of Channels *"
                            multi={false}
                            isSearch={false}
                            validate={true}
                            msg="Types of Channels field is required"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <SelectAdapter
                            name={`${this.props.lptName}.invesment`}
                            options={Investment}
                            placeholder="Investment (If any) *"
                            multi={false}
                            isSearch={false}
                            validate={true}
                            msg="Any investment field is required"
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="bp3-input-group">
                            <Field
                                name={`${this.props.lptName}.margin_commissions`}
                                component="input"
                                placeholder="Margin / Commissions % *"
                                className="bp3-input"
                                validate={required}
                                type="number"
                                min="0"
                            />
                            <Error name={`${this.props.lptName}.margin_commissions`}
                                   msg="Margin / Commissions field is required"/>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="bp3-input-group">
                            <Field
                                name={`${this.props.lptName}.min_area`}
                                component="input"
                                placeholder="Min Area *"
                                className="bp3-input"
                                validate={(value, allValues) => (
                                    value ? (parseInt(allValues.looking_partners_list[this.props.lptCount].max_area) >= parseInt(value)) ? undefined : 'Min' : 'Required'
                                )}
                                type="number"
                                min="0"
                            />
                            <Error name={`${this.props.lptName}.min_area`} msg="Min Area field is required"/>
                            <ErrorMin name={`${this.props.lptName}.min_area`} msg="Min is less then max area"/>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="bp3-input-group">
                            <Field
                                name={`${this.props.lptName}.max_area`}
                                component="input"
                                placeholder="Max Area *"
                                className="bp3-input"
                                validate={(value, allValues) => (
                                    value ? (parseInt(allValues.looking_partners_list[this.props.lptCount].min_area) <= parseInt(value)) ? undefined : 'Max' : 'Required'
                                )}
                                type="number"
                                min="0"
                            />
                            <Error name={`${this.props.lptName}.max_area`} msg="Max Area field is required"/>
                            <ErrorMax name={`${this.props.lptName}.max_area`} msg="Max is grater then min area"/>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <SelectAdapter
                            name={`${this.props.lptName}.area_type`}
                            options={AreaType}
                            placeholder="Types of Area *"
                            multi={false}
                            isSearch={false}
                            validate={true}
                            msg="Types of Area field is required"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TradePartners;
