import {ssoInstance} from "gg-react-utilities";
import Config from "../Config";

export const getFranchiseList = (page) => {
    return ssoInstance.get(Config.API_URL + 'v1/franchise?page='+page)
};

export const getMyFranchiseList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.API_URL + 'v1/franchise/my/user?'+queryString)
};

export const getAppliedFranchiseList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.API_URL + 'v1/franchise/apply/user?'+queryString)
};

export const getRatedFranchiseList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.API_URL + 'v1/franchise/rated/user?'+queryString)
};


export const getFranchiseDetail = (input) => {
    return ssoInstance.get(Config.API_URL + 'v1'+ input)
};

export const applyFranchise = (franchiseId) => {
    return ssoInstance.post(Config.API_URL + 'v1/franchise/apply?franchise_id=' + franchiseId)
}

export const saveFranchise = (franchiseId) => {
    return ssoInstance.post(Config.API_URL + 'v1/franchise/favourite?franchise_id=' + franchiseId)
}

export const addFranchiseReview = (formData) => {
    return ssoInstance.post(Config.API_URL + 'v1/franchise/rate', formData)
}


export const getSaveFranchiseList = (params) => {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return ssoInstance.get(Config.API_URL + 'v1/franchise/saved/user?'+queryString)
};