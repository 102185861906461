import React, {Component} from 'react';
import {Field} from 'react-final-form';
import FranchiseTypeList from '../staticData/FranchiseTypeList';
import BudgetList from '../staticData/BudgetList';
import TradePartners from './TradePartners';
import {Checkbox} from "@blueprintjs/core";
import {FieldArray} from 'react-final-form-arrays';
import TypeFranchisesConsidering from './TypeFranchisesConsidering';
import {Error, required, SelectAdapter, Condition} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";

class ProfessionalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state_list: [],
            city_list: [],
        }
    }

    componentWillMount() {
        this.setStateOrCity('state');
        this.setStateOrCity('city');
    }

    setStateOrCity = (type) => {
        ssoInstance.get(Config.API_URL + Config.API_PREFIX + "/" + type)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.name,
                            label: item.name
                        });
                    });

                    if (type === "state") this.setState({state_list: result});

                    if (type === "city") this.setState({city_list: result});
                }
            })
            .catch((errors) => {
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="pd-panel">
                    <div className="pd-title">
                        <h4>Professional Details</h4>
                    </div>
                    <div className="row">
                        {/*1.*/}
                        <div className="col-md-12 mb-5">
                            <label className="bp3-label">Are You Looking For Franchise Partners? *</label>
                            <div className="fp-main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="looking_franchise"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="looking_franchise"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>

                                <Error name="looking_franchise"/>
                            </div>
                            <Condition when="looking_franchise" is={"yes"}>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <label className="bp3-label">Looking For Franchise Partners? *</label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Field name="unit_chek_box_1" validate={required}>
                                                    {({input}) => (
                                                        <Checkbox
                                                            disabled={true}
                                                            checked={true}
                                                            label="Unit"
                                                            inline={true}
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>

                                                <Error name="unit_chek_box_1"/>
                                            </div>
                                            <div className="col-md-8">
                                                <Field name="unit_chek_box_2">
                                                    {({input}) => (
                                                        <Checkbox
                                                            label="Master / Multi Units"
                                                            inline={true}
                                                            {...input}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <SelectAdapter
                                            name={"store_wise"}
                                            options={FranchiseTypeList}
                                            placeholder="Type Of Franchise *"
                                            multi={false}
                                            isSearch={false}
                                            validate={true}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6 mb-3">
                                        <SelectAdapter
                                            name={"investment"}
                                            options={BudgetList}
                                            placeholder="Budget *"
                                            multi={false}
                                            isSearch={false}
                                            validate={true}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="brand_fee"
                                            component="input"
                                            placeholder="Franchise / Brand Fee *"
                                            className="bp3-input"
                                            validate={required}
                                            type="number"
                                            min="0"
                                        />
                                        <Error name="brand_fee"/>
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                            name="royalty"
                                            component="input"
                                            placeholder="Royalty / Commission % *"
                                            className="bp3-input"
                                            validate={required}
                                            type="number"
                                            min="0"
                                        />
                                        <Error name="royalty"/>
                                    </div>
                                </div>
                                {/*Mater Unit*/}
                                <Condition when="unit_chek_box_2" is={true}>
                                    <div className="row mt-3">
                                        <div className="col-md-12 mb-3">
                                            <label className="bp3-label">What Type Of Franchises Are You
                                                Considering?</label>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <Field name="unit_chek_box_3">
                                                {({input}) => (
                                                    <Checkbox
                                                        label="Country wise"
                                                        inline={true}
                                                        {...input}
                                                    />
                                                )}
                                            </Field>
                                            <Condition when="unit_chek_box_3" is={true}>
                                                <TypeFranchisesConsidering
                                                    typeNumber={1}
                                                />
                                            </Condition>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <Field name="unit_chek_box_4">
                                                {({input}) => (
                                                    <Checkbox
                                                        label="State wise"
                                                        inline={true}
                                                        {...input}
                                                    />
                                                )}
                                            </Field>
                                            <Condition when="unit_chek_box_4" is={true}>
                                                <TypeFranchisesConsidering
                                                    typeNumber={2}
                                                />
                                            </Condition>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <Field name="unit_chek_box_5">
                                                {({input}) => (
                                                    <Checkbox
                                                        label="City wise"
                                                        inline={true}
                                                        {...input}
                                                    />
                                                )}
                                            </Field>
                                            <Condition when="unit_chek_box_5" is={true}>
                                                <TypeFranchisesConsidering
                                                    typeNumber={3}
                                                />
                                            </Condition>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <Field name="unit_chek_box_6">
                                                {({input}) => (
                                                    <Checkbox
                                                        label="Region wise"
                                                        inline={true}
                                                        {...input}
                                                    />
                                                )}
                                            </Field>
                                            <Condition when="unit_chek_box_6" is={true}>
                                                <TypeFranchisesConsidering
                                                    typeNumber={4}
                                                />
                                            </Condition>
                                        </div>
                                    </div>
                                </Condition>
                            </Condition>
                        </div>
                        {/*End 1.*/}
                        {/*2.*/}
                        <div className="col-md-12 mb-5">
                            <label className="bp3-label">Are You Looking For Trade Partners
                                (Dealers, Distributors, MBOs,Retailers)? *</label>
                            <div className="fp_tp_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="looking_partners"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="looking_partners"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="looking_partners"/>
                            </div>
                            <Condition when="looking_partners" is={"yes"}>
                                <div className="mt-3">
                                    <FieldArray name="looking_partners_list">
                                        {({fields}) =>
                                            fields.map((name, index) => (
                                                <TradePartners
                                                    key={index}
                                                    lptCount={index}
                                                    lptName={name}
                                                    lptFields={fields}
                                                />
                                            ))
                                        }
                                    </FieldArray>
                                </div>
                            </Condition>
                        </div>
                        {/*End 2.*/}
                        {/*3.*/}
                        <div className="col-md-12 mb-5">
                            <label className="bp3-label">Are You Looking For International
                                Franchise? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="internastionl_franchise"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="internastionl_franchise"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="internastionl_franchise"/>
                            </div>
                        </div>
                        {/*End 3.*/}
                        {/*4.*/}
                        <div className="col-md-12 mb-5">
                            <label className="bp3-label">Where Are You Looking For Expansion?
                                *</label>
                            <div className="fp_exp_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="looking_expanstion"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} PAN India
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="looking_expanstion"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Select The Location
                                </label>
                                <Error name="looking_expanstion"/>
                            </div>
                            <Condition when="looking_expanstion" is={"no"}>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <label className="bp3-label">What Type Of Franchise Are You Considering?
                                            *</label>
                                        <label className="bp3-control bp3-inline bp3-radio">
                                            <Field
                                                name="across_india"
                                                component="input"
                                                type="radio"
                                                value="yes"
                                                validate={required}
                                            />
                                            <span className="bp3-control-indicator"></span>
                                            {' '} State Wise
                                        </label>
                                        <label className="bp3-control bp3-inline bp3-radio">
                                            <Field
                                                name="across_india"
                                                component="input"
                                                type="radio"
                                                value="no"
                                                validate={required}
                                            />
                                            <span className="bp3-control-indicator"></span>
                                            {' '} City Wise
                                        </label>

                                        <Error name={"across_india"} />
                                    </div>
                                    <Condition when="across_india" is={"yes"}>
                                        <div className="col-md-12  mt-3">
                                            <SelectAdapter
                                                name={"state_name_selected"}
                                                options={this.state.state_list}
                                                placeholder="Select State"
                                                multi={true}
                                                isSearch={true}
                                                validate={true}
                                                msg={"State field is required"}
                                            />
                                        </div>
                                    </Condition>
                                    <Condition when="across_india" is={"no"}>
                                        <div className="col-md-12  mt-3">
                                            <SelectAdapter
                                                name={"city_name_selected"}
                                                options={this.state.city_list}
                                                placeholder="Select City"
                                                multi={true}
                                                isSearch={true}
                                                validate={true}
                                                msg={"City field is required"}
                                            />
                                        </div>
                                    </Condition>
                                </div>
                            </Condition>
                        </div>
                        {/*End 4.*/}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProfessionalDetails;
