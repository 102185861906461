import React from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {Button, Intent} from "@blueprintjs/core";
import {Auth} from "gg-react-utilities";

export default class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };

    static Page = ({children}) => children;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues || {}
        }
    }

    next = values => {
        if (!Auth.isLoggedIn()) {
            Auth.login(true)
        } else {
            this.setState(state => ({
                page: Math.min(state.page + 1, this.props.children.length - 1),
                values
            }));
        }
    };

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
            ];
        return activePage.props.validate ? activePage.props.validate(values) : {}
    };

    handleSubmit = values => {
        const {children, onSubmit} = this.props;
        const {page} = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values)
        } else {
            this.next(values)
        }
    };

    render() {
        const {children} = this.props;
        const {page, values} = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="row">
                    <div className="col-xl-12 col-12 my-0">
                        <div className="franchise-sign-up-form mb-5">
                            <div className="main-form">
                                <div className="frn-top mb-5">
                                    <ul className="step d-flex flex-nowrap">
                                        <li className={"step-item " + (page === 0 ? "active" : "")}>
                                            <a href="#!" className="">Professional Details</a>
                                        </li>
                                        <li className={"step-item " + (page === 1 ? "active" : "")}>
                                            <a href="#!" className="">Business Details</a>
                                        </li>
                                        <li className={"step-item " + (page === 2 ? "active" : "")}>
                                            <a href="#!" className="">Question</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="frn-sub-top mb-4">
                                    <h2 className="mb-5 text-center">Are you happy with our service?</h2>
                                    <hr/>
                                </div>
                                <Form
                                    initialValues={values}
                                    validate={this.validate}
                                    mutators={{
                                        ...arrayMutators
                                    }}
                                    onSubmit={this.handleSubmit}
                                    render={({
                                                 handleSubmit,
                                                 reset,
                                                 submitting,
                                                 pristine,
                                                 validating,
                                                 values
                                             }) => {
                                        return (
                                            <form
                                                method="post"
                                                onSubmit={handleSubmit}
                                                encType="multipart/form-data"
                                            >
                                                <div className="frn-middle mb-5">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {activePage}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="frn-bottom mb-5">
                                                    <div className="pull-right">
                                                        <div className="buttons">
                                                            <Button
                                                                intent={Intent.NONE}
                                                                className={"mr-3 "}
                                                                disabled={page === 0 ? true : false}
                                                                onClick={this.previous}
                                                                text={"Prev Step"}
                                                            />
                                                            {
                                                                !isLastPage && (
                                                                    <Button
                                                                        type={"submit"}
                                                                        intent={Intent.NONE}
                                                                        className={"mr-3 "}
                                                                        text={"Next Step"}
                                                                    />
                                                                )}
                                                            {
                                                                isLastPage && (
                                                                    <Button
                                                                        type={"submit"}
                                                                        intent={Intent.PRIMARY}
                                                                        disabled={submitting}
                                                                        text={"submit"}
                                                                    />
                                                                )}
                                                        </div>
                                                    </div>
                                                    {/* <div className="row p-5">
                                                        <div className="col-md-12">
                                                            <pre>{JSON.stringify(values, 0, 2)}</pre>
                                                        </div>
                                                    </div>*/}
                                                </div>
                                            </form>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
