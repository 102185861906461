import React, {Component} from "react";
import FranchiseEmptyImage from '../../core/images/empty-state/No_frachise_found.svg';
import defaultImage from "../../core/images/default.png";
import StarRatings from 'react-star-ratings';


export default class AppliedFranchise extends Component {
    constructor(props) {
        super(props);

        this.state = {
            franchiseList: [],
            page: 1,
            stopLoadMore: false,
            showEmptyStats: false,
            scrollLoader: true,
            loading: true,
        }
    }

    componentDidMount() {
        let params = {
            page: this.state.page,
        }
        this.getAppliedFranchiseList(params)
    }

    getAppliedFranchiseList = (params) => {
        this.props.getAppliedFranchiseList(params)
            .then((res) => {
                const sources = res.data;
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 1000);
                if (sources.status === true) {
                    let oldData = this.state.franchiseList;
                    let newDataFromApi = sources.data.data;
                    let newData = oldData.concat(newDataFromApi);
                    this.setState({
                        franchiseList: newData,
                    });
                    if (sources.data.data === "") {
                        this.setState({
                            showEmptyStats: true,
                        });
                    }
                    if (sources.data.current_page === sources.data.last_page) {
                        this.setState({
                            stopLoadMore: true,
                        })
                    } else {
                        this.setState({
                            stopLoadMore: false,
                        })
                    }
                } else {

                }
            }).catch((error) => {
            //const sources = error.response;
        })
    }

    handleScroll = () => {
        var allBooking = document.getElementById('allBooking');
        var allBookingHeight = (allBooking.scrollHeight) - (allBooking.scrollTop + 590);

        if (parseInt(allBookingHeight) === 0) {
            if (this.state.stopLoadMore === false) {
                this.NextPage()
            }
        }
    }

    NextPage = () => {
        this.setState({
            scrollLoader: false
        })
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1,
            scrollLoader: true
        }, () => {
            let params = {
                page: this.state.page,
            }
            this.getAppliedFranchiseList(params)
        })

    }

    numDifferentiation(value) {
        let val = Math.abs(value);
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        } else if (val >= 1000) {
            val = (val / 1000).toFixed(2) + ' K';
        }
        return val;
    }

    render() {
        return (
            <React.Fragment>
                <div style={{height: '100%', maxHeight: '590px', overflowY: 'scroll'}} id={"allBooking"}
                     onScroll={this.handleScroll} className="row AppliedFranchise">
                    {
                        this.state.loading ?
                            <React.Fragment>
                                <div className="col-6">
                                    <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}></div>
                                </div>
                                <div className="col-6">
                                    <div className="bp3-skeleton" style={{width: "100%", height: "150px"}}></div>
                                </div>
                            </React.Fragment>
                            :
                            this.state.franchiseList.length ?
                                this.state.franchiseList.map((franchise, key) => {
                                    return (
                                        <div key={key} className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="p-3 px-s-0 px-sm-0">
                                                <ul className="franchise-list mr-0">
                                                    <li>
                                                        <div className='border rounded-0 franchise-box'>
                                                            <div className="row">
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                                    <center>
                                                                        <img src={franchise.brand_logo}
                                                                             alt="place"
                                                                             className="image border img-fluid ml-xl-1 ml-lg-1 ml-md-1 ml-sm-1 ml-s-0 mt-3 mt-xl-1 mt-lg-1 mt-sm-1 mt-md-1"
                                                                             onError={(ev) => {
                                                                                 ev.target.src = defaultImage;
                                                                             }}/>
                                                                    </center>
                                                                </div>
                                                                <div
                                                                    className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 pl-md-3 pl-sm-2 pl-3 mt-2 mb-2">
                                                                    <div className="pl-2 pt-2">
                                                                        <p className="Franchise-head">
                                                                            {((franchise.brand_name).length > 35) ? (((franchise.brand_name).substring(0, 35 - 3)) + '...') : franchise.brand_name}
                                                                        </p>
                                                                        <div className="f-star d-flex mb-2">
                                                                        <span
                                                                            className="review mr-2"> {franchise.avg_rating} </span>
                                                                            <p>
                                                                                <StarRatings
                                                                                    rating={franchise.avg_rating === null ? 0 : franchise.avg_rating}
                                                                                    starRatedColor="#F5A623"
                                                                                    starDimension='15px'
                                                                                    numberOfStars={5}
                                                                                    name='rating'
                                                                                />
                                                                            </p>
                                                                        </div>
                                                                        <div className="frach-contents my-0">
                                                                            <div className="invesment my-2">
                                                                                <b>Investment</b> :<span> ₹ {this.numDifferentiation(franchise.min_investment)} to ₹ {this.numDifferentiation(franchise.max_investment)} </span>
                                                                            </div>
                                                                            <div className="in-category my-2">
                                                                                <b>Category</b> :<span> {franchise.category_name} </span>
                                                                            </div>
                                                                            <div className="f-outlets my-2">
                                                                                <b>Franchise
                                                                                    Outlets</b> :<span> {franchise.no_of_outlets} </span>
                                                                                <b>Area
                                                                                    Req.</b> :<span> {franchise.min_area} - {franchise.max_area} Sq.ft</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="col-12">
                                    <div className="text-center">
                                        <img className="img-fluid product-img" src={FranchiseEmptyImage} alt=""/>
                                        <p className="mt-3">Sorry! there is no franchise.</p>
                                    </div>
                                </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}
