const NoOfOutlets = {
    'Less than 10': 'Less than 10',
    '10-20': '10-20',
    '20-50': '20-50',
    '50-100': '50-100',
    '100-200': '100-200',
    '200-500': '200-500',
    '500-1000': '500-1000',
    '1000-10000': '1000-10000',
    'More than 10000': 'More than 10000',
};

export default Object.keys(NoOfOutlets).map(value => ({
    value,
    label: NoOfOutlets[value]
}));
