import {connect} from 'react-redux';
import ApplyFranchise from "../components/ApplyFranchise";
import {applyFranchise} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        applyFranchise: state.FranchiseDetailList.applyFranchise
    }
};

const mapDispatchToProps = dispatch => {
    return {
        applyFranchise: (franchiseId) => {
            return applyFranchise(franchiseId);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyFranchise);