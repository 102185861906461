import React, {Component} from 'react';
import Wizard from './Wizard';
import BusinessDetails from "./BusinessDetails";
import QuestionDetails from "./QuestionDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import {onSubmit} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";

class WizardIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active_wizard: 1,
            franchiseList: ""
        };
    }

    componentDidMount() {
        let franchiseId = this.props.match.params.franchise_id ? this.props.match.params.franchise_id : "";
        if (franchiseId !== "") {
            this.getEditFranchise(franchiseId);
        }
    }

    getEditFranchise = (franchise_id) => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/franchise/edit/${franchise_id}`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    sources.data.map((item) => {
                        this.setState({
                            franchiseList: item
                        });
                    });
                }
            })
            .catch((errors) => {
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <Wizard
                    initialValues={{
                        unit_chek_box_1: true,
                        looking_partners_list: [null]
                    }}
                    onSubmit={onSubmit}
                >
                    <Wizard.Page>
                        <ProfessionalDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <BusinessDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <QuestionDetails/>
                    </Wizard.Page>
                </Wizard>
            </React.Fragment>
        );
    }
}

export default WizardIndex;
