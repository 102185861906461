let initialState = {
    franchiseDetail: null,
    applyFranchise: null,
    saveFranchise: null,
    addFranchiseReview: null,
};

const FranchiseDetailList = (state = initialState, action) => {

    switch (action.type) {
        case 'FRANCHISE_DETAIL':
            return {...state, franchiseDetail: action.sources};
        case 'FRANCHISE_APPLY':
            return {...state, applyFranchise: action.sources};
        case 'FRANCHISE_SAVE':
            return {...state, saveFranchise: action.sources};
        case 'ADD_FRANCHISE_REVIEW':
            return {...state, addFranchiseReview: action.sources};

        default:
            return state
    }
};

export default FranchiseDetailList;
