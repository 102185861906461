import {connect} from 'react-redux';
import Favorite from "../components/Favorite";
import {saveFranchise} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        saveFranchise: state.FranchiseDetailList.saveFranchise
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveFranchise: (franchiseId) => {
            return saveFranchise(franchiseId);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);