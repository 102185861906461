import React, {Component} from "react";
import TopBar from "../core/components/TopBar";
import AccountInfo from "./Components/AccountInfo";
import FranchiseInfo from "./Components/FranchiseInfo";
import {Auth, NoLogin} from 'gg-react-utilities';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: "",
            isLoggedIn: false
        }
    }

    async componentDidMount() {
        let isLoggedIn = await Auth.isLoggedIn();
        this.setState({
            isLoggedIn: isLoggedIn,
            userInfo: Auth.user(),
        })
    }

    render() {
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container mb-5">
                    <div className="upvWrapper">
                        <div className="upvContainer" style={{padding: '200px 0'}}>
                            <NoLogin/>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="container">
                    <TopBar title="My Franchise Account"/>
                    <AccountInfo userInfo={this.state.userInfo}/>
                    <div className="row my-2">
                        <div className="col-12">
                            <FranchiseInfo/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
