const LongFranchiseTerms = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '4+': '4+',
};

export default Object.keys(LongFranchiseTerms).map(value => ({
    value,
    label: LongFranchiseTerms[value]
}));
