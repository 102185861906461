let initialState = {
    franchiseList: null
};

const FranchiseList = (state = initialState, action) => {

    switch (action.type) {
        case 'FRANCHISE_LIST':
            return {...state, franchiseList: action.sources};
        default:
            return state
    }
};

export default FranchiseList;