import React, {Component} from 'react';
import defaultImage from "../../core/images/default.png";
import StarRatings from 'react-star-ratings';
import {Button, Tab, Tabs, Intent, Position, Toaster, Card} from "@blueprintjs/core";
import ApplyFranchise from '../container/ApplyFranchise';
import Favorite from '../container/Favorite';
import AddRating from './AddRating';

class FranchiseDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSources: [],
            loading: true,
            val: "",
            visible: 10,
            isOpen: false,
        };
    }

    componentDidMount() {
        let source = this.props.history.location.pathname ? this.props.history.location.pathname : "";
        this.props.getFranchiseDetail(source);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.franchiseDetail !== null) {
            this.setState({
                dataSources: nextProps.franchiseDetail !== undefined ? nextProps.franchiseDetail : [],
                loading: false
            });
        }
    }

    numDifferentiation(value) {
        let val = Math.abs(value);
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        } else if (val >= 1000) {
            val = (val / 1000).toFixed(2) + ' K';
        }
        return val;
    }

    addReview = (formData) => {
        this.props.addFranchiseReview(formData)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                    let source = this.props.history.location.pathname ? this.props.history.location.pathname : "";
                    this.props.getFranchiseDetail(source);
                }
            }).catch((error) => {
            const sources = error.response;
            Toaster.create({position: Position.TOP}).show({message: sources.data.message, intent: Intent.DANGER});
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="row">
                    {(false == this.state.loading) ? (
                            <React.Fragment>
                                <div className="col-xl-12 col-12 my-0">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="p-3 px-s-0 px-sm-0">
                                                <ul className="franchise-detail mr-0">
                                                    <li>
                                                        <div className='border rounded-0 franchise-box'>
                                                            <div className="row">
                                                                <div
                                                                    className="detail-img col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                                    <center>
                                                                        <img
                                                                            src={this.state.dataSources.franchise ? this.state.dataSources.franchise.brand_logo : ""}
                                                                            alt="place"
                                                                            className="image border img-fluid pull-left ml-xl-1 ml-lg-1 ml-md-1 ml-sm-1 ml-s-0 mt-3 mt-xl-1 mt-lg-1 mt-sm-1 mt-md-1 w-100"
                                                                            onError={(ev) => {
                                                                                ev.target.src = defaultImage;
                                                                            }}/>
                                                                    </center>
                                                                </div>
                                                                <div
                                                                    className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 pl-md-3 pl-sm-2 pl-3 mt-2 mb-2 pull-left">
                                                                    <div className="pl-2 pt-2">
                                                                        <div className="frnch-common d-flex">
                                                                            <p className="franchise-head">
                                                                                {this.state.dataSources.franchise ?
                                                                                    (this.state.dataSources.franchise.brand_name.length) ? (((this.state.dataSources.franchise.brand_name).substring(0, 35 - 3)) + '...') : this.state.dataSources.franchise.brand_name
                                                                                    : ""
                                                                                }
                                                                            </p>
                                                                            <div className="icon ml-auto addFavorite">
                                                                                <Favorite
                                                                                    show={this.state.dataSources.has_save}
                                                                                    franchiseId={this.state.dataSources.franchise ? this.state.dataSources.franchise.franchise_id : ""}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="f-star d-flex mb-2">
                                                                <span className="review mr-2">
                                                                    {this.state.dataSources.franchise ? this.state.dataSources.franchise.avg_rating : ""}
                                                                </span>
                                                                            <StarRatings
                                                                                rating={this.state.dataSources.franchise ? this.state.dataSources.franchise.avg_rating : 0}
                                                                                starRatedColor="#F5A623"
                                                                                starDimension='15px'
                                                                                numberOfStars={5}
                                                                                name='rating'
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="franch-con-main d-xl-flex align-items-md-end justify-content-around">
                                                                            <div
                                                                                className="frach-contents my-0 mr-auto w-75">
                                                                                <div className="invesment my-2">
                                                                                    <b>Investment : </b>
                                                                                    <span> ₹ {this.state.dataSources.franchise ? this.numDifferentiation(this.state.dataSources.franchise.min_investment) + ' to  ₹ ' + this.numDifferentiation(this.state.dataSources.franchise.max_investment) : ""}
                                                                    </span>
                                                                                </div>
                                                                                <div className="in-category my-2">
                                                                                    <b>Category
                                                                                        : </b><span> {this.state.dataSources.franchise ? this.state.dataSources.franchise.category_name : ""}</span>
                                                                                    <AddRating
                                                                                        franchiseLogo={this.state.dataSources.franchise ? this.state.dataSources.franchise.brand_logo : ""}
                                                                                        franchiseName={this.state.dataSources.franchise ? this.state.dataSources.franchise.brand_name : ""}
                                                                                        addReview={this.addReview}
                                                                                        franchiseId={this.state.dataSources.franchise ? this.state.dataSources.franchise.franchise_id : ""}
                                                                                    />
                                                                                </div>
                                                                                <div className="f-outlets my-2">
                                                                                    <b>Franchise Outlets
                                                                                        : </b><span> {this.state.dataSources.franchise ? this.state.dataSources.franchise.no_of_outlets : ""} </span>
                                                                                </div>
                                                                                <div className="f-outlets my-2">
                                                                                    <b>Area Req
                                                                                        : </b><span> {this.state.dataSources.franchise ? this.numDifferentiation(this.state.dataSources.franchise.min_area) + ' - ' + this.numDifferentiation(this.state.dataSources.franchise.max_area) : ""} Sq.ft</span>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.dataSources.has_apply === true ?
                                                                                    <Button intent="success"
                                                                                            className="bp3-button bp3-small success btn-view mr-1"
                                                                                            text="Applied"/>
                                                                                    :
                                                                                    <ApplyFranchise
                                                                                        isOpen={this.state.isOpen}
                                                                                        franchiseId={this.state.dataSources.franchise ? this.state.dataSources.franchise.franchise_id : ""}
                                                                                    />
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 franchise-detail-tabs">
                                    <div className="mb-4 mt-5 text-left p-0">
                                        <Tabs
                                            animate={true}
                                            id="TabsClassic"
                                            key="horizontal"
                                            renderActiveTabPanelOnly={true}
                                            vertical={false}
                                            className="bd-tabs"
                                        >
                                            <Tab
                                                className="bd-tab"
                                                id="tab1"
                                                title="Business Details"
                                                panel={
                                                    <div className="tab-details">
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border mt-5">
                                                            <div className="row french-head-main">
                                                                <div className="col-12 text-left">
                                                                    <h6 className="french-con-details my-3">Business
                                                                        Details</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <div className="pl-2 pt-2 text-left french-contents ">
                                                                    {this.state.dataSources.franchise ?
                                                                        <p dangerouslySetInnerHTML={{__html: this.state.dataSources.franchise.detail}}></p>
                                                                        :
                                                                        ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                            <Tab
                                                className="bd-tab"
                                                id="tab2"
                                                title="Investment"
                                                panel={
                                                    <div className="tab-details">
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border mt-5">
                                                            <div className="row french-head-main">
                                                                <div className="col-12 text-left">
                                                                    <h6 className="french-con-details my-3">Commenced
                                                                        Date</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <div className="pl-2 pt-1 french-head">
                                                                    <p>Date Commenced Operations</p>
                                                                    <p>Date Commenced Franchising / Distribution</p>
                                                                </div>
                                                                <div className="pl-2 pt-2 text-left french-contents ml-5">
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.date_comm_op : ""} </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.date_comm_fran : ""} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border border-top-0">
                                                            {this.state.dataSources.franchiseExpansion !== "" && this.state.dataSources.franchiseExpansion !== undefined ?
                                                                <React.Fragment>
                                                                    <div className="row french-head-main">
                                                                        <div className="col-12 text-left">
                                                                            <h6 className="french-con-details my-3">Expansion
                                                                                Locations</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="table-responsive">
                                                                        <table
                                                                            className="table no-margin table-bordered pb-2">
                                                                            <tr className="pl-2 pt-2 french-head">
                                                                                <th className="pull-left state-head">State</th>
                                                                                <th className="city-head">City</th>
                                                                            </tr>
                                                                            {this.state.dataSources.franchiseExpansion.map((res, index) => {
                                                                                return (
                                                                                    <tr className="pl-2 pt-2 text-left french-contents ml-5">
                                                                                        <td>{res.city_name !== "" ? res.city_name : "-"}</td>
                                                                                        <td>{res.state_name !== "" ? res.state_name : "-"}</td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </table>
                                                                    </div>
                                                                </React.Fragment>
                                                                : ""
                                                            }
                                                        </div>
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border border-top-0">
                                                            <div className="row french-head-main">
                                                                <div className="col-12 text-left">
                                                                    <h6 className="french-con-details my-3">Franchise
                                                                        Details
                                                                        (Optional)</h6>
                                                                </div>
                                                            </div>

                                                            <div className="row p-2">
                                                                <div className="pl-2 pt-1 french-head">
                                                                    <p>Want to expand</p>
                                                                    <p>Exclusive territorial rights to a unit</p>
                                                                    <p>Performance guarantee to unit franchise</p>
                                                                    <p>Anticipated percentage return on investment</p>
                                                                    <p>Likely pay back period of capital for a unit
                                                                        franchise</p>
                                                                    <p>Other investment requirements</p>
                                                                </div>
                                                                <div className="pl-2 pt-2 text-left french-contents ml-5">
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.fd_question_1 : ""} </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.fd_question_2 : ""} </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.fd_question_3 : ""} </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.fd_question_4 : ""} </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.fd_question_5 : ""} </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.fd_question_6 : ""} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            <Tab
                                                className="bd-tab"
                                                id="tab3"
                                                title="Property"
                                                panel={
                                                    <div className="tab-details">
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border mt-5">
                                                            <div className="row french-head-main">
                                                                <div className="col-12 text-left">
                                                                    <h6 className="french-con-details my-3">Property
                                                                        Detail</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <div className="pl-2 pt-2 french-head">
                                                                    <p>Type of property required for this franchise
                                                                        opportunity</p>
                                                                    <p>Floor area requirement</p>
                                                                    <p>Preferred location of unit franchised outlet</p>
                                                                </div>
                                                                <div className="pl-2 pt-2 text-left french-contents ml-5">
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.pro_question_1 : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.pro_question_2 : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.pro_question_3 : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            <Tab
                                                className="bd-tab"
                                                id="tab4"
                                                title="Training"
                                                panel={
                                                    <div className="tab-details">
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border mt-5">
                                                            <div className="row french-head-main">
                                                                <div className="col-12 text-left">
                                                                    <h6 className="french-con-details my-3">Training
                                                                        Detail</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <div className="pl-2 pt-2 french-head">
                                                                    <p>Detailed operating manuals for franchisees</p>
                                                                    <p>Where is franchisee training provided?</p>
                                                                    <p>Is field assistance available for franchisee?</p>
                                                                    <p>Will someone from Head Office provides assistance to
                                                                        franchisee in opening the franchise?</p>
                                                                    <p>What IT systems do you presently have that will be
                                                                        included
                                                                        in the franchise?</p>
                                                                </div>
                                                                <div className="pl-2 pt-2 text-left french-contents ml-5">
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.tr_question_1 : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ?
                                                                        this.state.dataSources.franchise.tr_question_2 === "yes" ? "At Franchisor's Location" : ""
                                                                        :
                                                                        ""
                                                                    }
                                                                        {this.state.dataSources.franchise ?
                                                                            this.state.dataSources.franchise.tr_question_2 === "no" ? "At Franchisee's Location" : ""
                                                                            :
                                                                            ""
                                                                        }
                                                                    </p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.tr_question_3 : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.tr_question_4 : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.tr_question_5 : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            <Tab
                                                className="bd-tab"
                                                id="tab5"
                                                title="Other"
                                                panel={
                                                    <div className="tab-details">
                                                        <div
                                                            className="col-xl-12 col-md-12 col-sm-12 col-lg-12 col-12 border mt-5">
                                                            <div className="row french-head-main">
                                                                <div className="col-12 text-left">
                                                                    <h6 className="french-con-details my-3">Agreement & Term
                                                                        Details</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row p-2">
                                                                <div className="pl-2 pt-2 french-head">
                                                                    <p>Do you have a standard franchise agreement?</p>
                                                                    <p>How long is the franchise term for?</p>
                                                                    <p>Is the term renewable?</p>
                                                                </div>
                                                                <div className="pl-2 pt-2 text-left french-contents ml-5">
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.oth_question_1 : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.oth_question_2 + ' Year' : ""}</p>
                                                                    <p>{this.state.dataSources.franchise ? this.state.dataSources.franchise.oth_question_3 : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />

                                            <Tab
                                                className="bd-tab"
                                                id="tab6"
                                                title="User Review/Rating"
                                                panel={
                                                    <div className="tab-details">
                                                        <div className="row mt-5">
                                                            {this.state.dataSources.ratings ? this.state.dataSources.ratings.map((res, index) => {
                                                                    return (
                                                                        <div key={index}
                                                                             className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                                                            <div className="card border p-4 p-s-3">
                                                                                <div className="row">
                                                                                    <div className="col-md-7 col-sm-7 col-7"><h4
                                                                                        className="text-title m-0 pull-left">{res.sso_username}</h4>
                                                                                    </div>
                                                                                    {/*<div className="col-md-5 col-sm-5 col-5 text-nowrap d-flex justify-content-end align-items-center">*/}
                                                                                    <div className="f-star d-flex mb-2">
                                                                            <span className="review mr-2">
                                                                            {res.rate !== null ? res.rate : 0}
                                                                            </span>
                                                                                        <p>
                                                                                            <StarRatings
                                                                                                rating={res.rate !== null ? res.rate : 0}
                                                                                                starRatedColor="#F5A623"
                                                                                                starDimension='15px'
                                                                                                numberOfStars={5}
                                                                                                name='rating'
                                                                                            />
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-md-7 col-sm-7 col-7">
                                                                                        <p className="text-secondary bp3-text-small mb-0 pull-left">{res.created_date}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            />

                                        </Tabs>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) :
                        <React.Fragment>
                            <div className="col-xl-12 col-12 my-0 mb-4">
                                <Card>
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                            <div className="bp3-skeleton"
                                                 style={{width: "100%", height: "210px"}}></div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                            <div className="pl-2 pt-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "40%", height: "26px"}}></div>
                                            </div>

                                            <div className="pl-2 pt-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "45%", height: "26px"}}></div>
                                            </div>
                                            <div className="pl-2 pt-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "50%", height: "26px"}}></div>
                                            </div>
                                            <div className="pl-2 pt-2">
                                                <div className="row">
                                                    <div className="col-xl-8 col-lg-8 col-md-8 mt-s-2 my-0">
                                                        <div className="bp3-skeleton"
                                                             style={{width: "76%", height: "26px"}}></div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 mt-s-2 my-0">
                                                        <div className="bp3-skeleton"
                                                             style={{width: "70%", height: "26px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pl-2 pt-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "45%", height: "26px"}}></div>
                                            </div>
                                            <div className="pl-2 pt-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "50%", height: "26px"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>

                            <div className="col-xl-12 col-12 my-0 mb-4 mt-5">
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 mt-s-2 my-0">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "50px"}}></div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 mt-s-2 my-0">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "50px"}}></div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 mt-s-2 my-0">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "50px"}}></div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 mt-s-2 my-0">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "50px"}}></div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 mt-s-2 my-0">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "50px"}}></div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 mt-s-2 my-0">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "50px"}}></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-12 my-0 mb-4">
                                <Card>
                                    <div className="col-xl-6 col-6 my-0 mb-2">
                                        <div className="row">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "25px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "60%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "60%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                <Card>
                                    <div className="col-xl-6 col-6 my-0 mb-2">
                                        <div className="row">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "25px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>

                                <Card>
                                    <div className="col-xl-6 col-6 my-0 mb-2">
                                        <div className="row">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "25px"}}></div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "20px"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </React.Fragment>
                    }
                </div>

            </React.Fragment>
        );
    }
}

export default FranchiseDetailComponent;
