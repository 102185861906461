export const getFranchiseListAction = (sources) => {
    return {
        type: 'FRANCHISE_LIST',
        sources: sources
    }
};

export const getMyFranchiseListAction = (sources) => {
    return {
        type: 'MY_FRANCHISE_LIST',
        sources: sources
    }
};

export const getAppliedFranchiseListAction = (sources) => {
    return {
        type: 'APPLIED_FRANCHISE_LIST',
        sources: sources
    }
};

export const getRatedFranchiseListAction = (sources) => {
    return {
        type: 'RATED_FRANCHISE_LIST',
        sources: sources
    }
};

export const getFranchiseDetailAction = (sources) => {
    return {
        type: 'FRANCHISE_DETAIL',
        sources: sources
    }
};

export const applyFranchiseAction = (sources) => {
    return {
        type: 'FRANCHISE_APPLY',
        sources: sources
    }
};

export const saveFranchiseAction = (sources) => {
    return {
        type: 'FRANCHISE_SAVE',
        sources: sources
    }
};

export const addFranchiseReviewAction = (sources) => {
    return {
        type: 'ADD_FRANCHISE_REVIEW',
        sources: sources
    }
};


export const getSaveFranchiseListAction = (sources) => {
    return {
        type: 'SAVE_FRANCHISE_LIST',
        sources: sources
    }
};