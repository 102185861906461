let initialState = {
    franchiseList: null
};

const Home = (state = initialState, action) => {

    switch (action.type) {
        case 'Home_LIST':
            return {...state, franchiseList: action.sources};
        default:
            return state
    }
};

export default Home;
