import {connect} from "react-redux";
import AppliedFranchise from "../Components/AppliedFranchise";
import {getAppliedFranchiseList} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        appliedFranchiseList: state.AccountInfo.appliedFranchiseList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAppliedFranchiseList: (params) => {
            return getAppliedFranchiseList(params);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFranchise);