import React, {Component} from 'react';
import {Alert, Button, Intent, Position, Toaster} from "@blueprintjs/core";

export default class ApplyFranchise extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        }

        this.handleClose = this.handleClose.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen === true) {
            this.handleClose();
        }
    }

    handleOpen = () => {
        this.setState({isOpen: true});
    }

    handleClose() {
        this.setState({isOpen: false});
    }

    acceptRequest = () => {
        this.props.applyFranchise(this.props.franchiseId)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                }
            }).catch((error) => {
            const sources = error.response;
            Toaster.create({position: Position.TOP}).show({message: sources.data.message, intent: Intent.DANGER});
            this.handleClose();
        })
    }

    render() {
        return (
            <React.Fragment>
                <Button intent="primary" className="bp3-button bp3-small bp3-intent-primary btn-view mr-1" text="Apply"
                        onClick={this.handleOpen}/>
                <Alert
                    className="bp3-small"
                    icon="confirm"
                    cancelButtonText="Cancel"
                    confirmButtonText="Confirm"
                    intent={Intent.PRIMARY}
                    isOpen={this.state.isOpen}
                    onCancel={this.handleClose}
                    onConfirm={this.acceptRequest}
                    canOutsideClickCancel="true"
                    canEscapeKeyCancel="true"
                >
                    <p className="warningHeading">Are you sure you want to apply for franchise ?</p>
                </Alert>
            </React.Fragment>
        );
    }

}
