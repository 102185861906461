import React, {Component} from 'react';
import {Field, FormSpy} from 'react-final-form';
import NoOfOutlets from "../staticData/NoOfOutlets";
import {FileInput, Intent, TextArea} from "@blueprintjs/core";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import DatePicker from "react-datepicker";
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';
import {OnChange} from "react-final-form-listeners";

const RelatedSelectiOnChanges = ({field, set}) => (
    <Field name={set} subscription={{value: null}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value, previous) => {
                    if (value !== previous) {
                        onChange(undefined);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

//export const required = value => (value ? undefined : 'Required');
class BusinessDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country_list: [],
            country_list_status: false,
            state_list: [],
            state_list_status: false,
            city_list: [],
            city_list_status: false,
            pincode_list: [],
            pincode_list_status: false,
            category_list: [],
            category_list_status: false,
            sub_category_list: [],
            sub_category_list_status: false,
            sub_sub_category_list: [],
            sub_sub_category_list_status: false,
        }
    }

    componentWillMount() {
        this.setCategory();
        this.setCountryList();
    }

    setCountryList = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/country`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.id,
                            label: item.name
                        });
                    });
                    this.setState({country_list: result, country_list_status: true});
                }
            })
            .catch((errors) => {
            });
    };

    setStateOrCityListById = (type, id) => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/${type}/${id}`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.id,
                            label: item.name
                        });
                    });
                    if (type === "state") this.setState({state_list: result, country_list_status: true});
                    if (type === "city") this.setState({city_list: result, state_list_status: true});
                }
            })
            .catch((errors) => {
            });
    };

    setPinCodeListByCityId = (id) => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/pin-code/${id}`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.id,
                            label: item.pincode
                        });
                    });
                    this.setState({pincode_list: result, city_list_status: true, pincode_list_status: true});
                }
            })
            .catch((errors) => {
            });
    };

    setStateByCountryId = (value) => {
        this.setStateOrCityListById('state', value.value);
    };

    setCityByStateId = (value) => {
        this.setStateOrCityListById('city', value.value)
    };

    setPinCodeByCityId = (value) => {
        this.setPinCodeListByCityId(value.value)
    };

    setCategory = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/category`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.category_id,
                            label: item.category_name
                        });
                    });
                    this.setState({category_list: result, category_list_status: true})
                }
            })
            .catch((errors) => {
            });
    };

    setSubCategory = (type, id) => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/category/${id}/subcategory`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.category_id,
                            label: item.category_name
                        });
                    });
                    if (type === "sub_cat") this.setState({sub_category_list: result, sub_category_list_status: true});
                    if (type === "sub_sub_cat") this.setState({
                        sub_sub_category_list: result,
                        sub_sub_category_list_status: true
                    });
                }
            })
            .catch((errors) => {
            });
    };

    setSubCategoryByCategoryId = (value) => {
        this.setSubCategory('sub_cat', value.value);
    };

    setSubSubCategoryByCategoryId = (value) => {
        this.setSubCategory('sub_sub_cat', value.value);
    };

    render() {
        return (
            <React.Fragment>
                <div className="pd-panel">
                    <div className="pd-title">
                        <h4>Business Details</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="brand_name"
                                    component="input"
                                    placeholder="Company Name *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="brand_name"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="contact_person_name"
                                    component="input"
                                    placeholder="Contact Person Name *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="contact_person_name"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="contact_phone_number"
                                    component="input"
                                    placeholder="Contact Phone Number *"
                                    className="bp3-input"
                                    validate={required}
                                    type="tel"
                                    minLength="10"
                                    maxLength="10"
                                    pattern="[0-9]{10}"
                                    title="Please put 10 digit phone number"
                                />
                                <Error name="contact_phone_number"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="email_id"
                                    component="input"
                                    placeholder="Email *"
                                    className="bp3-input"
                                    validate={required}
                                    type="email"
                                    pattern="[a-zA-Z0-9.!#$%&amp;’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+"
                                    title="Invalid email address"
                                />
                                <Error name="email_id"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="street_1"
                                    component="input"
                                    placeholder="Street 1 *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="street_1"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="street_2"
                                    component="input"
                                    placeholder="Street 2 *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="street_2"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="landmark"
                                    component="input"
                                    placeholder="Landmark *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="landmark"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="area"
                                    component="input"
                                    placeholder="Area *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="area"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="country_id">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"country_id"}
                                                    options={this.state.country_list}
                                                    placeholder="Country *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByCountryId}
                                                    {...input}
                                                />
                                                {
                                                    form.getFieldState('country_id') ?
                                                        form.getFieldState('country_id').value !== undefined
                                                            ?
                                                            this.state.country_list_status === false ?
                                                                this.setStateByCountryId({value: form.getFieldState('country_id').value})
                                                                : ""
                                                            : ""
                                                        : ""
                                                }
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="state_id">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"state_id"}
                                                    options={this.state.state_list}
                                                    placeholder="State *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setCityByStateId}

                                                    {...input}
                                                />
                                                {
                                                    form.getFieldState('state_id') ?
                                                        form.getFieldState('state_id').value !== undefined
                                                            ?
                                                            this.state.state_list_status === false ?
                                                                this.setCityByStateId({value: form.getFieldState('state_id').value})
                                                                : ""
                                                            : ""
                                                        : ""
                                                }
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                            <RelatedSelectiOnChanges field="state_id" set="city_id"/>
                            <RelatedSelectiOnChanges field="state_id" set="pincode_id"/>
                        </div>
                        <div className="col-md-6 mb-5">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="city_id">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"city_id"}
                                                    options={this.state.city_list}
                                                    placeholder="City *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setPinCodeByCityId}

                                                    {...input}
                                                />
                                                {
                                                    form.getFieldState('city_id') ?
                                                        form.getFieldState('city_id').value !== undefined
                                                            ?
                                                            this.state.city_list_status === false ?
                                                                this.setPinCodeByCityId({value: form.getFieldState('city_id').value})
                                                                : ""
                                                            : ""
                                                        : ""
                                                }
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                            <RelatedSelectiOnChanges field="city_id" set="pincode_id"/>
                        </div>
                        <div className="col-md-6 mb-5">
                            <SelectAdapter
                                name={"pincode_id"}
                                options={this.state.pincode_list}
                                placeholder="Pincode *"
                                multi={false}
                                isSearch={true}
                                validate={true}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="website"
                                    component="input"
                                    placeholder="Website *"
                                    className="bp3-input"
                                    validate={required}
                                    type="url"
                                    pattern="https?://.+"
                                    title="Include http:// or https://"
                                />
                                <Error name="website"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <SelectAdapter
                                name={"no_of_outlets"}
                                options={NoOfOutlets}
                                placeholder="No. Of Franchise Outlets *"
                                multi={false}
                                isSearch={false}
                                validate={true}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="min_investment"
                                    component="input"
                                    placeholder="Minimum investment *"
                                    className="bp3-input"
                                    validate={(value, allValues) => (
                                        value ? (parseInt(allValues.max_investment) >= parseInt(value)) ? undefined : 'Min' : 'Required'
                                    )}
                                    type="number"
                                    min="0"
                                    step={1}

                                />
                                <Error name="min_investment"/>
                                <ErrorMin name="min_investment" msg="Min is less then max investment"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="max_investment"
                                    component="input"
                                    placeholder="Maximum investment *"
                                    className="bp3-input"
                                    validate={(value, allValues) => (
                                        value ? (parseInt(allValues.min_investment) <= parseInt(value)) ? undefined : 'Max' : 'Required'
                                    )}
                                    type="number"
                                    min="0"
                                    step={1}
                                />
                                <Error name="max_investment"/>
                                <ErrorMax name="max_investment" msg="Max is grater then min investment"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="min_area"
                                    component="input"
                                    placeholder="Minimum Area *"
                                    className="bp3-input"
                                    validate={(value, allValues) => (
                                        value ? (parseInt(allValues.max_area) >= parseInt(value)) ? undefined : 'Min' : 'Required'
                                    )}
                                    type="number"
                                    min="0"
                                    step={1}
                                />
                                <Error name="min_area"/>
                                <ErrorMin name="min_area" msg="Min area is less then max area"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="max_area"
                                    component="input"
                                    placeholder="Maximum Area *"
                                    className="bp3-input"
                                    validate={(value, allValues) => (
                                        value ? (parseInt(allValues.min_area) <= parseInt(value)) ? undefined : 'Max' : 'Required'
                                    )}
                                    type="number"
                                    min="0"
                                    step={1}
                                />
                                <Error name="max_area"/>
                                <ErrorMax name="max_area" msg="Max area is grater then min area"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="category_id">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"category_id"}
                                                    options={this.state.category_list}
                                                    placeholder="Categories *"
                                                    multi={false}
                                                    isSearch={false}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setSubCategoryByCategoryId}

                                                    {...input}
                                                />
                                                {
                                                    form.getFieldState('category_id') ?
                                                        form.getFieldState('category_id').value !== undefined
                                                            ?
                                                            this.state.category_list_status === false ?
                                                                this.setSubCategoryByCategoryId({value: form.getFieldState('category_id').value})
                                                                : ""
                                                            : ""
                                                        : ""
                                                }
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                            <RelatedSelectiOnChanges field="category_id" set="sub_category_id"/>
                            <RelatedSelectiOnChanges field="category_id" set="sub_sub_category_id"/>
                        </div>
                        <div className="col-md-6 mb-5">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="sub_category_id">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"sub_category_id"}
                                                    options={this.state.sub_category_list}
                                                    placeholder="Industry Sub Category *"
                                                    multi={false}
                                                    isSearch={false}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setSubSubCategoryByCategoryId}

                                                    {...input}
                                                />
                                                {
                                                    form.getFieldState('sub_category_id') ?
                                                        form.getFieldState('sub_category_id').value !== undefined
                                                            ?
                                                            this.state.sub_category_list_status === false ?
                                                                this.setSubSubCategoryByCategoryId({value: form.getFieldState('sub_category_id').value})
                                                                : ""
                                                            : ""
                                                        : ""
                                                }
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                            <RelatedSelectiOnChanges field="sub_category_id" set="sub_sub_category_id"/>
                        </div>
                        <div className="col-md-6 mb-5">
                            <SelectAdapter
                                name={"sub_sub_category_id"}
                                options={this.state.sub_sub_category_list}
                                placeholder="Industry Sub Classification *"
                                multi={false}
                                isSearch={false}
                                validate={true}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="commission"
                                    component="input"
                                    placeholder="Franchise commission % *"
                                    className="bp3-input"
                                    validate={required}
                                    type="number"
                                    min="0"
                                />
                                <Error name="commission"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="date_comm_op"
                                    className="bp3-input"
                                    validate={required}
                                    render={({input, meta, ...rest}) => (
                                        <DatePicker
                                            {...input}
                                            {...rest}
                                            dateFormat="YYYY"
                                            placeholderText="Date Commenced Operations *"
                                            onChange={(e) => {
                                                input.onChange(moment(e).format("YYYY"));
                                            }}
                                            showYearDropdown
                                            showMonthYearPicker
                                        />
                                    )}
                                />
                                <Error name="date_comm_op"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="date_comm_fran"
                                    className="bp3-input"
                                    validate={required}
                                    render={({input, meta, ...rest}) => (
                                        <DatePicker
                                            {...input}
                                            {...rest}
                                            dateFormat="YYYY"
                                            placeholderText="Date Commenced Franchising / Distribution *"
                                            onChange={(e) => {
                                                input.onChange(moment(e).format("YYYY"));
                                            }}
                                            showYearDropdown
                                            showMonthYearPicker
                                        />
                                    )}
                                />
                                <Error name="date_comm_fran"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <Field
                                name="brand_logo"
                                render={({input, meta, ...rest}) => (
                                    <FileInput
                                        {...rest}
                                        {...input}
                                        fill={true}
                                        large={true}
                                        text="Brand Logo ( JPG, PNG, JPEG) *"
                                        inputProps={{accept: "image/*"}}
                                        onChange={(e) => {
                                            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                                                document.getElementById('brand_logo_filename').textContent = "";
                                                input.onChange("");
                                                return false;
                                            }

                                            document.getElementById('brand_logo_filename').textContent = e.target.files[0].name;
                                            input.onChange(e.target.files[0]);
                                        }}
                                    />
                                )}
                                validate={required}
                            />
                            <Error name="brand_logo"/>
                            <div id="brand_logo_filename" className="bold"></div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <Field
                                name="cover_photo"
                                render={({input, meta, ...rest}) => (
                                    <FileInput
                                        {...rest}
                                        {...input}
                                        fill={true}
                                        large={true}
                                        text="Cover Logo ( JPG, PNG, JPEG) *"
                                        inputProps={{accept: "image/*"}}
                                        onChange={async (e) => {
                                            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                                                document.getElementById('cover_photo_filename').textContent = "";
                                                input.onChange("");
                                                return false;
                                            }

                                            document.getElementById('cover_photo_filename').textContent = e.target.files[0].name;
                                            input.onChange(e.target.files[0]);
                                        }}
                                    />
                                )}
                                validate={required}
                            />
                            <Error name="cover_photo"/>
                            <div id="cover_photo_filename" className="bold"></div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <Field
                                name="detail"
                                validate={required}
                            >
                                {({input}) => (
                                    <TextArea
                                        growVertically={true}
                                        large={true}
                                        intent={Intent.PRIMARY}
                                        placeholder={"Enter your detail..."}
                                        {...input}
                                    />
                                )}
                            </Field>
                            <Error name="detail"/>
                            {/*<Field
                                name="detail"
                                component={
                                    ({input}) => (
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data=""
                                            {...input}
                                        />
                                    )
                                }
                            />*/}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BusinessDetails;
