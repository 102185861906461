const InvestmentTimePeriod = {
    'Less than 3 months': 'Less than 3 months',
    '3 - 6 months': '3 - 6 months',
    '6 - 12 months': '6 - 12 months'
};

export default Object.keys(InvestmentTimePeriod).map(value => ({
    value,
    label: InvestmentTimePeriod[value]
}));
