import React, {Component} from 'react';
import {Button} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import defaultImage from "../../core/images/default.png";
import StarRatings from 'react-star-ratings';

class FranchiseListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            franchises: [],
            loadMore: true,
            page: 1,
        }
    }

    numDifferentiation(value) {
        let val = Math.abs(value);
        if (val >= 10000000) {
            val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
            val = (val / 100000).toFixed(2) + ' Lac';
        } else if (val >= 1000) {
            val = (val / 1000).toFixed(2) + ' K';
        }
        return val;
    }

    loadMore(){
        this.props.loadMoreAction();
    }

    render() {
        if (this.props.franchises !== undefined) {
            return (
                <React.Fragment>
                    {
                        this.props.franchises.map((res, index) => {
                            return (
                                <div key={index} className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <Link to={'/franchise/' + res.slug}>
                                        <div className="p-3 px-s-0 px-sm-0">
                                            <ul className="franchise-list mr-0">
                                                <li>
                                                    <div className='border rounded-0 franchise-box'>
                                                        <div className="row">
                                                            <div
                                                                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                                <center>
                                                                    <img src={res.brand_logo}
                                                                         alt="place"
                                                                         className="image border img-fluid ml-xl-1 ml-lg-1 ml-md-1 ml-sm-1 ml-s-0 mt-3 mt-xl-1 mt-lg-1 mt-sm-1 mt-md-1"
                                                                         onError={(ev) => {
                                                                             ev.target.src = defaultImage;
                                                                         }}/>
                                                                </center>
                                                            </div>
                                                            <div
                                                                className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 pl-md-3 pl-sm-2 pl-3 mt-2 mb-2">
                                                                <div className="pl-2 pt-2">
                                                                    <p className="Franchise-head">
                                                                        {((res.brand_name).length > 35) ? (((res.brand_name).substring(0, 35 - 3)) + '...') : res.brand_name}
                                                                        {/*<Favorite*/}
                                                                        {/*    show={res.has_save}*/}
                                                                        {/*    franchiseId={res.franchise_id}*/}
                                                                        {/*/>*/}
                                                                    </p>
                                                                    <div className="icon ml-auto addFavorite">
                                                                    </div>
                                                                    <div className="f-star d-flex mb-2">
                                                                        <span
                                                                            className="review mr-2"> {res.avg_rating} </span>
                                                                            <StarRatings
                                                                                rating={res.avg_rating === null ? 0 : res.avg_rating}
                                                                                starRatedColor="#F5A623"
                                                                                starDimension='15px'
                                                                                numberOfStars={5}
                                                                                name='rating'
                                                                            />
                                                                    </div>
                                                                    <div className="frach-contents my-0">
                                                                        <div className="invesment my-2">
                                                                            <b>Investment</b> :<span> ₹ {this.numDifferentiation(res.min_investment)} to ₹ {this.numDifferentiation(res.max_investment)} </span>
                                                                        </div>
                                                                        <div className="in-category my-2">
                                                                            <b>Category</b> :<span> {res.category_name} </span>
                                                                        </div>
                                                                        <div className="f-outlets my-2">
                                                                            <b>Franchise
                                                                                Outlets</b> :<span> {res.no_of_outlets} </span>
                                                                            <b>Area
                                                                                Req.</b> :<span> {res.min_area} - {res.max_area} Sq.ft</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                    <div className="text-center col-md-12 mb-3">
                        <div className="col-12 align-content-center">
                            <div className="col-12 justify-content-center text-center">
                                { this.props.stopLoadMore ?
                                    <Button
                                    small={true}
                                    intent="default"
                                    text="That's its"
                                    />
                                    :
                                    <Button
                                        small={true}
                                        intent="primary"
                                        text="Show More"
                                        onClick={ () => this.loadMore()}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div>
                    <h3>No records found</h3>
                </div>
            );
        }
        // } else {
        //     return (
        //
        //         <div className="text-center">
        //
        //             <div className="row">
        //                 <div className="col-md-12 mt-5">
        //                     <p>Sorry! We didn't found any brand for this category. You can still try for other category</p>
        //                 </div>
        //
        //                 <div className="col-md-12 mt-5">
        //                     <img src={no_affiliates} />
        //                 </div>
        //
        //                 <div className="col-md-12 mt-5">
        //                     <Link to="/bsw/home" className="btn btn-primary">Explore</Link>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
    }
}

export default FranchiseListing;

