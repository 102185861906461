import React, {Component} from "react";
import TopBar from "../core/components/TopBar";
import FranchiseDetailComponent from "./container/FranchiseDetailContainer";

export default class FranchiseDetail extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar back={this.props.history} title="Franchise Detail"/>
                    <FranchiseDetailComponent
                        {...this.props}
                    />
                </div>
            </React.Fragment>
        )
    }
}
