import React, {Component} from 'react';
import BaselineStars from "../../core/images/svg/baselineStars.svg";
import {Classes, Dialog, Intent, Position, Toaster} from "@blueprintjs/core";
import {Button} from "@blueprintjs/core";
import defaultImage from "../../core/images/default.png";
import StarRatings from 'react-star-ratings';


export default class ApplyFranchise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            review: '',
            rating: null,
            ratingError: '',
            ratingErrorClass: '',
            isLoginStatus: false,
        }

        this.handleClose = this.handleClose.bind(this)
        this.changeRating = this.changeRating.bind(this);
    }

    handleOpen = () => {
        this.setState({isOpen: true});
    }

    handleClose() {
        this.setState({isOpen: false, rating: null});
    }

    changeRating(newRating, name) {
        this.setState({
            rating: newRating
        });
    }

    submitReview() {
        if (this.state.rating !== null) {
            const formData = new FormData();
            formData.append('rate', this.state.rating)
            formData.append('franchise_id', this.props.franchiseId)
            this.setState({
                isLoginStatus: true
            });
            this.stopLogin();
            this.props.addReview(formData)
        } else {
            Toaster.create({position: Position.TOP}).show({message: "Please Rate us.", intent: Intent.WARNING});
        }
    }

    stopLogin = () => {
        setTimeout(() => {
            this.setState({
                isLoginStatus: false,
            });
            this.handleClose();
        }, 2000);
    }

    render() {
        return (
            <React.Fragment>
                <span className="addReview d-flex" onClick={() => this.handleOpen()}><img src={BaselineStars} alt=""/>Add Review</span>
                <Dialog isOpen={this.state.isOpen}
                        onClose={() => this.handleClose()}
                        className=""
                        title=""
                        canOutsideClickClose={true}
                        canEscapeKeyClose={true}>
                    <div className={Classes.DIALOG_BODY}>
                        <div className="mg-brand-payments">
                            <div className="paymentWrapper">
                                <div className="upperPaymentWrapper">
                                </div>
                                <div className="middlePaymentWrapper">
                                    <div className="circleWrapper">
                                        <div className="iconWrapper">
                                            <img
                                                src={this.props.franchiseLogo}
                                                alt="place"
                                                className="img-fluid "
                                                onError={(ev) => {
                                                    ev.target.src = defaultImage;
                                                }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4 row lowerWrapper'>
                                    <div className='col-xl-12 text-center'>
                                        <h4><b>{this.props.franchiseName}</b></h4>
                                    </div>

                                    <div className='col-xl-12 text-center'>
                                        <div className={"bp3-form-content " + this.state.ratingErrorClass}>
                                            <div className="bp3-input-group">
                                                <StarRatings
                                                    rating={this.state.rating !== null ? this.state.rating : 0}
                                                    starRatedColor="#F5A623"
                                                    starHoverColor="#F5A623"
                                                    changeRating={this.changeRating}
                                                    starDimension='40px'
                                                    numberOfStars={5}
                                                    name='rating'
                                                />
                                                <span className='text-left error-text'>{this.state.ratingError}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Button text='Not Now' className='bp3-button bp3-button bp3-secondary mb-3 mt-4'
                                                onClick={this.handleClose}/> &nbsp; &nbsp;
                                        <Button text='Submit'
                                                className='bp3-button bp3-intent-primary bp3-button bp3-intent-primary mb-3 mt-4'
                                                loading={this.state.isLoginStatus}
                                                onClick={() => {
                                                    this.submitReview()
                                                }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }

}
