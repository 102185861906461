import {connect} from "react-redux";
import { Toaster, Intent, Position } from '@blueprintjs/core';
import FranchiseComponent from "../components/FranchiseComponent";
import {getFranchiseList} from "../../core/actions/action";
import {getFranchiseListAction} from "../../core/actions";

let obj = {
    franchiseList: []
};

const mapStateToProps = state => {
    return {
        franchiseList: state.FranchiseList.franchiseList
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getFranchiseList: (page) => {

            dispatch(getFranchiseListAction([]));
            return getFranchiseList(page)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(getFranchiseListAction(sources.data))
                    } else {
                        dispatch(getFranchiseListAction([]));
                        Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                    }
                }).catch((error) => {
                    const sources = error.response;
                    Toaster.create({position: Position.TOP}).show({message:sources.message,intent: Intent.DANGER});
                })
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FranchiseComponent);