import React, {Component} from "react";
import CoverImage from "../../core/images/Cover.png"
import ProfileImage from "../../core/images/default.png"
import DOB from "../../core/images/MyAccountImage/round-cake-24px.svg";
import Call from "../../core/images/MyAccountImage/round-call-24px.svg";
import Email from "../../core/images/MyAccountImage/baseline-email-24px.svg";
import Location from "../../core/images/MyAccountImage/round-location_on-24px.svg";

export default class AccountInfo extends Component {

    render() {
        const cover_image = {backgroundImage: `url(${CoverImage})`};
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 mb-3 banner mt-s-4">
                        <div className="bg-img" style={cover_image}>
                        </div>
                    </div>
                    <div className="col-12 userInfo">
                        <img src={this.props.userInfo.avatar_url}
                             className="img-fluid product-img"
                             alt=""
                             onError={(ev)=>{
                                 ev.target.src = ProfileImage;
                             }}/>

                        <span className="float-right userDetails">
                            <p>
                                <span><img src={DOB} alt=""/> {this.props.userInfo.dob}</span>
                                <span><img src={Email} alt=""/> {this.props.userInfo.email}</span>
                            </p>
                            <p>
                                <span><img src={Call} alt=""/> {this.props.userInfo.mobile}</span>
                                <span><img src={Location} alt=""/> {this.props.userInfo.house_no +','+ this.props.userInfo.landmark +','+ this.props.userInfo.city +','+ this.props.userInfo.state +','+ this.props.userInfo.country +','+ this.props.userInfo.pincode}</span>
                            </p>
                        </span>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}