import React, {Component} from 'react';
import {Field, FormSpy} from 'react-final-form';
import {FileInput, Intent, TextArea} from "@blueprintjs/core";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import {OnChange} from "react-final-form-listeners";
import Config from "../../core/Config";
import moment from "moment";
import DatePicker from "react-datepicker";

const RelatedSelectiOnChanges = ({field, set}) => (
    <Field name={set} subscription={{value: null}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value, previous) => {
                    if (value !== previous) {
                        onChange(undefined);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

export default class BasicDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country_list: [],
            country_list_status: false,
            state_list: [],
            state_list_status: false,
            city_list: [],
            city_list_status: false,
            pincode_list: [],
            pincode_list_status: false,
        }
    }

    componentWillMount() {
        this.setCountryList();
    }

    setCountryList = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/country`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.id,
                            label: item.name
                        });
                    });
                    this.setState({country_list: result, country_list_status: true});
                }
            })
            .catch((errors) => {
            });
    };

    setStateOrCityListById = (type, id) => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/${type}/${id}`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.id,
                            label: item.name
                        });
                    });
                    if (type === "state") this.setState({state_list: result, country_list_status: true});
                    if (type === "city") this.setState({city_list: result, state_list_status: true});
                }
            })
            .catch((errors) => {
            });
    };

    setPinCodeListByCityId = (id) => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/pin-code/${id}`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.pincode,
                            label: item.pincode
                        });
                    });
                    this.setState({pincode_list: result, city_list_status: true, pincode_list_status: true});
                }
            })
            .catch((errors) => {
            });
    };

    setStateByCountryId = (value) => {
        this.setStateOrCityListById('state', value.value);
    };

    setCityByStateId = (value) => {
        this.setStateOrCityListById('city', value.value)
    };

    setPinCodeByCityId = (value) => {
        this.setPinCodeListByCityId(value.value)
    };

    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="investor_name"
                                component="input"
                                className="bp3-input"
                                validate={required}
                                pattern="[a-z A-Z].+"
                                minLength={4}
                                tooShort="You need a longer name"
                            />
                            <label className="form-control-placeholder">Name <span className="text-danger">*</span></label>
                            <Error name="investor_name"/>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="email"
                                component="input"
                                className="bp3-input"
                                type="email"
                                pattern="[a-zA-Z0-9.!#$%&amp;’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+"
                                validate={required}
                            />
                            <label className="form-control-placeholder">Email <span className="text-danger">*</span></label>
                            <Error name="email"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="mobile"
                                component="input"
                                className="bp3-input"
                                validate={required}
                                minLength="10"
                                maxLength="10"
                                pattern="[0-9]{10}"

                            />
                            <label className="form-control-placeholder">Mobile <span className="text-danger">*</span></label>
                            <Error name="mobile"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="landline"
                                component="input"
                                className="bp3-input"
                                minLength="10"
                                maxLength="13"
                                pattern="[0-9]{10}"
                            />
                            <label className="form-control-placeholder">Landline <span className="text-danger">*</span></label>
                            <Error name="landline"/>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="address"
                                component="input"
                                className="bp3-input"
                                validate={required}
                                pattern="[a-zA-Z0-9.^,. `]+"
                            />
                            <label className="form-control-placeholder">Address <span className="text-danger">*</span></label>
                            <Error name="address"/>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="country_id">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"country_id"}
                                                    options={this.state.country_list}
                                                    placeholder="Country *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByCountryId}

                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('country_id') ?*/}
                                                {/*        form.getFieldState('country_id').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.country_list_status === false ?*/}
                                                {/*                this.setStateByCountryId({value: form.getFieldState('country_id').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-4 mt-2">
                        <FormSpy
                            subscription={{values: true}}
                            render={({form}) => (
                                    <Field name="state_id">
                                    {({input}) => (
                                        <div>
                                            <SelectAdapter
                                                name={"state_id"}
                                                options={this.state.state_list}
                                                placeholder="State *"
                                                multi={false}
                                                isSearch={true}
                                                validate={true}
                                                msg=""
                                                handleSelectOnChange={this.setCityByStateId}

                                                {...input}
                                            />
                                            {/*{*/}
                                            {/*    form.getFieldState('state_id') ?*/}
                                            {/*        form.getFieldState('state_id').value !== undefined*/}
                                            {/*            ?*/}
                                            {/*            this.state.state_list_status === false ?*/}
                                            {/*                this.setCityByStateId({value: form.getFieldState('state_id').value})*/}
                                            {/*                : ""*/}
                                            {/*            : ""*/}
                                            {/*        : ""*/}
                                            {/*}*/}
                                        </div>
                                    )}
                                </Field>
                            )}
                        />
                        <RelatedSelectiOnChanges field="state_id" set="city_id"/>
                        <RelatedSelectiOnChanges field="state_id" set="pincode_id"/>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-4 mt-2">
                        <FormSpy
                            subscription={{values: true}}
                            render={({form}) => (
                                <Field name="city_id">
                                    {({input}) => (
                                        <div>
                                            <SelectAdapter
                                                name={"city_id"}
                                                options={this.state.city_list}
                                                multi={false}
                                                isSearch={true}
                                                validate={true}
                                                msg=""
                                                handleSelectOnChange={this.setPinCodeByCityId}
                                                {...input}
                                            />
                                            {/*{*/}
                                            {/*    form.getFieldState('city_id') ?*/}
                                            {/*        form.getFieldState('city_id').value !== undefined*/}
                                            {/*            ?*/}
                                            {/*            this.state.city_list_status === false ?*/}
                                            {/*                this.setPinCodeByCityId({value: form.getFieldState('city_id').value})*/}
                                            {/*                : ""*/}
                                            {/*            : ""*/}
                                            {/*        : ""*/}
                                            {/*}*/}
                                        </div>
                                    )}
                                </Field>
                            )}
                        />
                        <RelatedSelectiOnChanges field="city_id" set="pincode_id"/>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <SelectAdapter
                                name={"pincode"}
                                options={this.state.pincode_list}
                                placeholder="Pincode *"
                                multi={false}
                                isSearch={true}
                                validate={true}
                            />
                            {/*<label className="form-control-placeholder">PinCode <span className="text-danger">*</span></label>*/}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            {/*<Field*/}
                            {/*    name="dob"*/}
                            {/*    component="input"*/}
                            {/*    className="bp3-input"*/}
                            {/*    validate={required}*/}
                            {/*    required*/}
                            {/*/>*/}
                            <Field
                                name="dob"
                                className="bp3-input"
                                validate={required}
                                render={({input, meta, ...rest}) => (
                                    <DatePicker
                                        {...input}
                                        {...rest}
                                        dateFormat="YYYY-MM-DD"
                                        placeholderText="Date Of Birth *"
                                        onChange={(e) => {
                                            input.onChange(moment(e).format("YYYY-MM-DD"));
                                        }}
                                        maxDate={new Date()}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                )}
                            />
                            {/*<label className="form-control-placeholder">Date of Birth <span className="text-danger">*</span></label>*/}
                            <Error name="dob"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="occupation"
                                component="input"
                                className="bp3-input"
                                pattern="[a-z A-Z].+"
                                validate={required}

                            />
                            <label className="form-control-placeholder">Occupation <span className="text-danger">*</span></label>
                            <Error name="occupation"/>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <Field
                                name="qualification"
                                component="input"
                                className="bp3-input"
                                pattern="[a-z A-Z].+"
                                validate={required}

                            />
                            <label className="form-control-placeholder">Education Qualification <span className="text-danger">*</span></label>
                            <Error name="qualification"/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}