import React, {Component} from "react";
import FranchiseListing from "./FranchiseListing";
import {Card} from "@blueprintjs/core";

class FranchiseComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            franchises: [],
            val: "",
            loadMore: true,
            page: 1,
            showLoader: true,
            hideButton: false,
            dataSources: []
        };
    }

    componentDidMount() {
        let page = this.state.page;
        this.props.getFranchiseList(page);
    }

    loadMore() {
        let oldPageNo = this.state.page;
        this.setState({
            page: oldPageNo + 1
        }, () => {
            let page = this.state.page;
            this.props.getFranchiseList(page)
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.franchiseList) {
            this.setState({
                dataSources: this.state.dataSources.concat(nextProps.franchiseList.data !== undefined ? nextProps.franchiseList.data : []),
                loadMore: false,
            });

            if (nextProps.franchiseList.current_page === nextProps.franchiseList.last_page) {
                this.setState({
                    stopLoadMore: true,
                })
            } else {
                this.setState({
                    stopLoadMore: false,
                })
            }
        } else {
            this.setState({
                loadMore: false,
                loading: true,
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="row">
                    <div className="col-xl-12 col-12 my-0">
                        <div className="col-12 mb-3 mt-4 text-left p-0">
                            <p className="bp3-text-xlarge title text-center-s">Popular Franchise</p>
                        </div>
                        <div className="row mb-4">
                            {
                                (this.state.dataSources.length > 0) ? (
                                    <FranchiseListing
                                        franchises={this.state.dataSources}
                                        loadMore={this.state.loadMore}
                                        stopLoadMore={this.state.stopLoadMore}
                                        loadMoreAction={() => {
                                            this.loadMore()
                                        }}
                                        pageNo={this.state.page}
                                    />
                                ) :
                                <React.Fragment>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                        <Card>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                    <div className="franchise-box bp3-skeleton"
                                                         style={{width: "100%", height: "150px"}}></div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "50%", height: "22px"}}></div>
                                                    </div>

                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "60%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "80%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "70%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "100%", height: "22px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                        <Card>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                    <div className="franchise-box bp3-skeleton"
                                                         style={{width: "100%", height: "150px"}}></div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "50%", height: "22px"}}></div>
                                                    </div>

                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "60%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "80%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "70%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "100%", height: "22px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                        <Card>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                    <div className="franchise-box bp3-skeleton"
                                                         style={{width: "100%", height: "150px"}}></div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "50%", height: "22px"}}></div>
                                                    </div>

                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "60%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "80%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "70%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "100%", height: "22px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                        <Card>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                    <div className="franchise-box bp3-skeleton"
                                                         style={{width: "100%", height: "150px"}}></div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "50%", height: "22px"}}></div>
                                                    </div>

                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "60%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "80%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "70%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "100%", height: "22px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                        <Card>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                    <div className="franchise-box bp3-skeleton"
                                                         style={{width: "100%", height: "150px"}}></div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "50%", height: "22px"}}></div>
                                                    </div>

                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "60%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "80%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "70%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "100%", height: "22px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4">
                                        <Card>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mt-s-2 my-0">
                                                    <div className="franchise-box bp3-skeleton"
                                                         style={{width: "100%", height: "150px"}}></div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mt-s-2 my-0">
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "50%", height: "22px"}}></div>
                                                    </div>

                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "60%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "80%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "70%", height: "22px"}}></div>
                                                    </div>
                                                    <div className="pl-2 pt-2">
                                                        <div className="franchise-box bp3-skeleton"
                                                             style={{width: "100%", height: "22px"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FranchiseComponent;
