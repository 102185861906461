import {connect} from "react-redux";
import SavedFranshise from "../Components/SavedFranshise";
import {getSaveFranchiseList, saveFranchise} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        saveFranchiseList: state.AccountInfo.saveFranchiseList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSaveFranchiseList: (params) => {
            return getSaveFranchiseList(params);
        },

        saveFranchise: (franchiseId) => {
            return saveFranchise(franchiseId);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedFranshise);