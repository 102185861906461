let initialState = {
    franchiseList: [],
    ratedFranchiseList: [],
    appliedFranchiseList: [],
    saveFranchiseList: [],
};

const AccountInfo = (state = initialState, action) => {

    switch (action.type) {
        case 'MY_FRANCHISE_LIST':
            return {...state, franchiseList: action.sources};
        case 'APPLIED_FRANCHISE_LIST':
            return {...state, appliedFranchiseList: action.sources};
        case 'RATED_FRANCHISE_LIST':
            return {...state, ratedFranchiseList: action.sources};
        case 'SAVE_FRANCHISE_LIST':
            return {...state, saveFranchiseList: action.sources};
        default:
            return state
    }
};

export default AccountInfo;