import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {PackageConfigure, GgUtils} from 'gg-react-utilities';
import {packageConfigure} from './core/Config';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import Config from '../src/core/Config';

if (Config.APP_ENV === "Production") {
    GgUtils.sentryScripts(Config.REACT_APP_SENTRY_URL);
    GgUtils.googleAnalyticalScripts(Config.REACT_APP_GOOGLE_ANALYTICAL_CODE);
}
ReactDOM.render(
    <React.Fragment>
        <App/>
        <PackageConfigure {...packageConfigure} />
    </React.Fragment>
    , document.getElementById('root'));
unregisterServiceWorker();
