import React, {Component} from 'react';
import {Field, FormSpy} from 'react-final-form';
import {FileInput, Intent, TextArea} from "@blueprintjs/core";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import {OnChange} from "react-final-form-listeners";
import Config from "../../core/Config";
import moment from "moment";
import {Condition} from "../../FranchiseSignup/CommonFunction";

const RelatedSelectiOnChanges = ({field, set}) => (
    <Field name={set} subscription={{value: null}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value, previous) => {
                    if (value !== previous) {
                        onChange(undefined);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

export default class JobDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category_list: [],
            category_list_status: false,
        }
    }

    componentWillMount() {
        this.setCategory();
    }

    setCategory = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/category`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.category_id,
                            label: item.category_name
                        });
                    });
                    this.setState({category_list: result, category_list_status: true})
                }
            })
            .catch((errors) => {
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <label className="bp3-label">Job Experiences <span className="text-danger">*</span></label>
                        <div className="fp-main">
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="job_exp"
                                    component="input"
                                    type="radio"
                                    value="1"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} Yes
                            </label>
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="job_exp"
                                    component="input"
                                    type="radio"
                                    value="0"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} No
                            </label>

                            <Error name="job_exp"/>
                        </div>
                    </div>
                    <Condition when="job_exp" is={"1"}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                            <div className="bp3-input-group">
                                <FormSpy
                                    subscription={{values: true}}
                                    render={({form}) => (
                                        <Field name="job_industry">
                                            {({input}) => (
                                                <div>
                                                    <SelectAdapter
                                                        name={"job_industry"}
                                                        options={this.state.category_list}
                                                        placeholder="Industry Type *"
                                                        multi={false}
                                                        isSearch={false}
                                                        validate={true}
                                                        msg=""
                                                        handleSelectOnChange={this.setSubCategoryByCategoryId}
                                                        {...input}
                                                    />
                                                    {/*{*/}
                                                    {/*    form.getFieldState('category_id') ?*/}
                                                    {/*        form.getFieldState('category_id').value !== undefined*/}
                                                    {/*            ?*/}
                                                    {/*            this.state.category_list_status === false ?*/}
                                                    {/*                this.setSubCategoryByCategoryId({value: form.getFieldState('category_id').value})*/}
                                                    {/*                : ""*/}
                                                    {/*            : ""*/}
                                                    {/*        : ""*/}
                                                    {/*}*/}
                                                </div>
                                            )}
                                        </Field>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                            <div className="bp3-input-group">
                                <Field
                                    name="job_no_of_years"
                                    component="input"
                                    className="bp3-input"
                                    validate={required}
                                    requiredsubmit
                                    pattern="[0-9]+"
                                />
                                <label className="form-control-placeholder">No. of Years <span
                                    className="text-danger">*</span></label>
                                <Error name="job_no_of_years"/>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                            <div className="bp3-input-group">
                                <Field
                                    name="job_any_other_industry"
                                    component="input"
                                    className="bp3-input"
                                    validate={required}
                                    pattern="[a-zA-Z.^ `]+"
                                />
                                <label className="form-control-placeholder">Any Other Industry <span
                                    className="text-danger">*</span></label>
                                <Error name="job_any_other_industry"/>
                            </div>
                        </div>
                    </Condition>
                </div>
            </React.Fragment>
        );
    }
}