import {connect} from "react-redux";
import MyFranchise from "../Components/MyFranchise";
import {getMyFranchiseList} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        franchiseList: state.AccountInfo.franchiseList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMyFranchiseList: (params) => {
            return getMyFranchiseList(params);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyFranchise);