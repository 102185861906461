import React, {Component} from 'react';
import {Field, FormSpy} from 'react-final-form';
import {FileInput, Intent, TextArea} from "@blueprintjs/core";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import {OnChange} from "react-final-form-listeners";
import Config from "../../core/Config";
import moment from "moment";

export default class OtherDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <label className="bp3-label">Interested In Master Franchise <span className="text-danger">*</span></label>
                        <div className="fp-main">
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="interested_in_master"
                                    component="input"
                                    type="radio"
                                    value="1"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} Yes
                            </label>
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="interested_in_master"
                                    component="input"
                                    type="radio"
                                    value="0"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} No
                            </label>

                            <Error name="interested_in_master"/>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <label className="bp3-label">Are You The Sole Decision Maker <span className="text-danger">*</span></label>
                        <div className="fp-main">
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="sole_decision_maker"
                                    component="input"
                                    type="radio"
                                    value="1"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} Yes
                            </label>
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="sole_decision_maker"
                                    component="input"
                                    type="radio"
                                    value="0"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} No
                            </label>

                            <Error name="sole_decision_maker"/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}