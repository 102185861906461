import React, {Component} from "react";
import TopBar from "../core/components/TopBar";
import WizardIndex from "./components/WizardIndex";
import {Auth} from "gg-react-utilities";

export default class FranchiseEdit extends Component {

    componentWillMount() {
        if (!Auth.isLoggedIn()) {
            Auth.login(true)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar back={this.props.history} title="Franchise Sign Up"/>
                    <WizardIndex {...this.props} />
                </div>
            </React.Fragment>
        )
    }
}
