import {connect} from 'react-redux';
import FranchiseDetailComponent from "../components/FranchiseDetailComponent";
import {getFranchiseDetail, addFranchiseReview} from "../../core/actions/action";
import {getFranchiseDetailAction} from "../../core/actions";

const mapStateToProps = state => {
    return {
        franchiseDetail: state.FranchiseDetailList.franchiseDetail,
        addFranchiseReview: state.FranchiseDetailList.addFranchiseReview
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFranchiseDetail: (input) => {
            dispatch(getFranchiseDetailAction(null));
            return getFranchiseDetail(input)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(getFranchiseDetailAction(sources.data))
                    } else {
                        dispatch(getFranchiseDetailAction([]));
                    }
                }).catch((error) => {

                })
        },

        addFranchiseReview: (formData) => {
            return addFranchiseReview(formData);
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FranchiseDetailComponent);
