const MinArea = {
    '50': '50',
    '100': '100',
    '200': '200',
    '300': '300',
    '400': '400',
    '500': '500'
};

export default Object.keys(MinArea).map(value => ({
    value,
    label: MinArea[value]
}));
