import React, {Component} from "react";
import {Tab, Tabs} from "@blueprintjs/core";
import MyFranchise from "../Container/MyFranchise";
import AppliedFranchise from "../Container/AppliedFranchise";
import SavedFranshise from "../Container/SavedFranshise";
import MyRatings from "../Container/MyRatings";

export default class FranchiseInfo extends Component {

    render() {
        return (
            <React.Fragment>
                <Tabs
                    animate={false}
                    id="ordersTab"
                    renderActiveTabPanelOnly={true} vertical={false}>
                    <Tab id="MyFranchise" title="My Franchise" panel={<MyFranchise/>}/>
                    <Tab id="AppliedFranchise" title="Applied Franchise" panel={<AppliedFranchise/>}/>
                    <Tab id="SavedFranshise" title="Saved Franshise" panel={<SavedFranshise/>}/>
                    <Tab id="MyRatings" title="My Ratings" panel={<MyRatings/>}/>
                </Tabs>

            </React.Fragment>
        )
    }
}
