import React, {Component} from 'react';
import Like from "../../core/images/svg/Like.svg";
import UnLike from "../../core/images/svg/unlike.svg";
import {Auth} from "gg-react-utilities";
import {Intent, Position, Toaster} from "@blueprintjs/core";

export default class FavoriteIcon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isLoggedIn: false
        }
    }

    async componentDidMount() {
        let isLoggedIn = await Auth.isLoggedIn();
        this.setState({
            isLoggedIn: isLoggedIn
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            show: nextProps.show,
        })
    }

    handleToggleClick = (step) => {
        this.props.saveFranchise(this.props.franchiseId)
            .then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    Toaster.create({position: Position.TOP}).show({message: sources.message, intent: Intent.SUCCESS});
                    if (step === 1) {
                        this.setState({
                            show: true,
                        })
                    } else {
                        this.setState({
                            show: false,
                        })
                    }

                }
            }).catch((error) => {
            const sources = error.response;
            Toaster.create({position: Position.TOP}).show({message: sources.data.message, intent: Intent.DANGER});
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.show === false ?
                        <img className="veg mr-2" src={UnLike} alt="" onClick={() => this.handleToggleClick(1)}/>
                        :
                        <img className="veg mr-2" src={Like} alt="" onClick={() => this.handleToggleClick(0)}/>
                }
            </React.Fragment>
        );

    }

}
