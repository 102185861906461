import React, {Component} from 'react';
import {Field, FormSpy} from 'react-final-form';
import {FileInput, Intent, TextArea} from "@blueprintjs/core";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import {OnChange} from "react-final-form-listeners";
import MinArea from "../staticData/MinArea";
import MaxArea from "../staticData/MaxArea";
import Config from "../../core/Config";
import moment from "moment";
import {Condition} from "../../investorregister/CommonFunction";
import AreaType from "../staticData/AreaType";
import PropertyUse from "../staticData/PropertyUse";

const RelatedSelectiOnChanges = ({field, set}) => (
    <Field name={set} subscription={{value: null}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value, previous) => {
                    if (value !== previous) {
                        onChange(undefined);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

export default class PropertyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category_list: [],
            category_list_status: false,
            Investment_period: [],
            Investment_list_status: false,
            Minimum_investment: [],
            Minimum_list_status: false,
            maximum_investment: [],
            maximum_list_status: false,
            Min_area: [],
            Min_area_list_status: false,
            Max_area: [],
            Max_area_list_status: false,
            Area_type: [],
            Area_list_status: false,
            Property_use: [],
            Property_use_list_status: false,
        }
    }

    componentWillMount() {
        this.setCategory();
    }

    setCategory = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/category`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.category_id,
                            label: item.category_name
                        });
                    });
                    this.setState({category_list: result, category_list_status: true})
                }
            })
            .catch((errors) => {
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <label className="bp3-label">Commercial Property <span className="text-danger">*</span></label>
                        <div className="fp-main">
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="com_property_flag"
                                    component="input"
                                    type="radio"
                                    value="1"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} Yes
                            </label>
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="com_property_flag"
                                    component="input"
                                    type="radio"
                                    value="0"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} No
                            </label>

                            <Error name="com_property_flag"/>
                        </div>
                    </div>
                    <Condition when="com_property_flag" is={"1"}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div className="bp3-input-group">
                            <Field
                                name="com_property_address"
                                component="input"
                                className="bp3-input"
                                validate={required}
                                pattern="[a-zA-Z.^,. `]+"
                            />
                            <label className="form-control-placeholder">Address <span className="text-danger">*</span></label>
                            <Error name="com_property_address"/>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="min_area">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"min_area"}
                                                    options={MinArea}
                                                    placeholder="Min Area *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByMin_area}
                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('Min_area') ?*/}
                                                {/*        form.getFieldState('Min_area').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.Min_area_list_status === false ?*/}
                                                {/*                this.setStateByMin_area({value: form.getFieldState('Min_area').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="max_area">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"max_area"}
                                                    options={MaxArea}
                                                    placeholder="Max Area *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByMax_area}

                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('Max_area') ?*/}
                                                {/*        form.getFieldState('Max_area').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.Max_area_list_status === false ?*/}
                                                {/*                this.setStateByMax_area({value: form.getFieldState('Max_area').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="area">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"Area Type"}
                                                    options={AreaType}
                                                    placeholder="Area Type *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByArea_type}

                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('Area_type') ?*/}
                                                {/*        form.getFieldState('Area_type').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.Area_type_list_status === false ?*/}
                                                {/*                this.setStateByArea_type({value: form.getFieldState('Area_type').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="property_use">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"Property Use"}
                                                    options={PropertyUse}
                                                    placeholder="Property Use *"
                                                    multi={false}
                                                    isSearch={true}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByProperty_use}

                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('Property_use') ?*/}
                                                {/*        form.getFieldState('Property_use').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.Property_use_list_status === false ?*/}
                                                {/*                this.setStateByProperty_use({value: form.getFieldState('Property_use').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <label className="bp3-label">Parking Space <span className="text-danger">*</span></label>
                        <div className="fp-main">
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="parking_space"
                                    component="input"
                                    type="radio"
                                    value="1"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} Yes
                            </label>
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="parking_space"
                                    component="input"
                                    type="radio"
                                    value="0"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} No
                            </label>

                            <Error name="parking_space"/>
                        </div>
                    </div>
                    </Condition>
                </div>
            </React.Fragment>
        );
    }
}