import React from 'react'
import {Route} from 'react-router-dom'
import {ScrollTop} from 'gg-react-utilities';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        <div className="franchise">

            <Component {...props} />

            <ScrollTop/>
        </div>
    )}/>
);

export default PrivateRoute;
