const APP_ENV = 'Production'; // local,DEV,Production
const APP_EXTENSION = '.com';
const MAINTENANCE_MODE = false;

const config = {

    ENV                 : APP_ENV,
    IS_MAINTENANCE_MODE : MAINTENANCE_MODE,
    EXTENSION           : APP_EXTENSION,

    APP_URL             : 'https://franchise.globalgarner.com',
    API_URL             : 'https://franchise-api.globalgarner.com/api/',
    ACCOUNT_API_URL     : 'https://accounts.globalgarner.com/api/',

    DASHBOARD_URL       : 'https://dashboard.globalgarner.com/',
    USER_PROFILE_URL    : 'https://dashboard.globalgarner.com/profile',
    STORE_API_URL       : 'https://store-api.globalgarner.com/v1.0.3/',
    MEGABRAND_API_URL   : 'https://mega-brands-api.globalgarner.in/user/v1.0.3/'
};

if(APP_ENV === 'local'){
    config.APP_URL              = 'http://localhost:3000/';
    config.API_URL              = 'http://franchise-api.gg.localhost/api/';
    config.ACCOUNT_API_URL      = 'http://accounts.gg.localhoast/';

    config.DASHBOARD_URL        = 'https://dashboard.globalgarner.in/';
    config.USER_PROFILE_URL     = 'https://dashboard.globalgarner.in/profile';
    config.STORE_API_URL        = 'https://beta-store-api.globalgarner.in/v1.0.3/';
    config.MEGABRAND_API_URL    = 'https://beta-mega-brands-api.globalgarner.in/user/v1.0.3/';
}

if(APP_ENV === 'DEV'){
    config.APP_URL              = 'https://beta-franchise.globalgarner.in';
    config.API_URL              = 'https://beta-franchise-api.globalgarner.in/api/';
    config.ACCOUNT_API_URL      = 'https://beta-accounts.globalgarner.in/';

    config.DASHBOARD_URL        = 'https://beta-dashboard.globalgarner.in/';
    config.USER_PROFILE_URL     = 'https://beta-dashboard.globalgarner.in/profile';
    config.STORE_API_URL        = 'https://beta-store-api.globalgarner.in/v1.0.3/';
    config.MEGABRAND_API_URL    = 'https://beta-mega-brands-api.globalgarner.in/user/v1.0.3/';
}

if(APP_ENV === 'Production'){
    config.APP_URL              = 'https://franchise.globalgarner.com';
    config.API_URL              = 'https://franchise-api.globalgarner.com/api/';
    config.ACCOUNT_API_URL      = 'https://accounts.globalgarner.com/';

    config.DASHBOARD_URL        = 'https://dashboard.globalgarner.com/';
    config.USER_PROFILE_URL     = 'https://dashboard.globalgarner.com/profile';
    config.STORE_API_URL        = 'https://store-api.globalgarner.com/v1.0.3/';
    config.MEGABRAND_API_URL    = 'https://mega-brands-api.globalgarner.com/user/v1.0.3/';
}

export const packageConfigure={
    APP_URL             : config.APP_URL,
    ACCOUNT_API_URL     : config.ACCOUNT_API_URL,
    DASHBOARD_URL       : config.DASHBOARD_URL,
    USER_PROFILE_URL    : config.USER_PROFILE_URL,
    STORE_API_URL       : config.STORE_API_URL,
    MEGABRAND_API_URL   : config.MEGABRAND_API_URL,
    ADMIN_API_URL       : config.ADMIN_API_URL,
    APP_ENV             : config.ENV,
    Extension           : config.EXTENSION,
};

config.REACT_APP_GOOGLE_ANALYTICAL_CODE='UA-92068611-18';
config.REACT_APP_SENTRY_URL='https://fcdb1bb5c3a0496fb1effa6ca2a48cdd@sentry.io/1413310';

export default config;
