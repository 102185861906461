import React, {Component} from 'react';
import BudgetList from '../staticData/BudgetList';
import {Field} from "react-final-form";
import {Error, required, SelectAdapter} from "../CommonFunction";

class TypeFranchisesConsidering extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row mt-3">
                    <div className="col-md-12 mb-3">
                        <SelectAdapter
                            name={"investment_" + this.props.typeNumber}
                            options={BudgetList}
                            placeholder="Budget *"
                            multi={false}
                            isSearch={false}
                            validate={true}
                            msg={"Budget field is required"}
                        />
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="bp3-input-group">
                            <Field
                                name={"unit_fee_" + this.props.typeNumber}
                                component="input"
                                placeholder="Unit / Brand Fee *"
                                className="bp3-input"
                                validate={required}
                                type="number"
                                min="0"
                            />
                            <Error name={"unit_fee_" + this.props.typeNumber} msg="Unit / Brand Fee field is required"/>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="bp3-input-group">
                            <Field
                                name={"master_fee_" + this.props.typeNumber}
                                component="input"
                                placeholder="Master / Brand Fee *"
                                className="bp3-input"
                                validate={required}
                                type="number"
                                min="0"
                            />
                            <Error name={"master_fee_" + this.props.typeNumber}
                                   msg="Master / Brand Fee field is required"/>
                        </div>
                    </div>

                    <div className="col-md-12 mb-3">
                        <div className="bp3-input-group">
                            <Field
                                name={"royalty_" + this.props.typeNumber}
                                component="input"
                                placeholder="Royalty / Commission % *"
                                className="bp3-input"
                                validate={required}
                                type="number"
                                min="0"
                            />
                            <Error name={"royalty_" + this.props.typeNumber}
                                   msg="Royalty / Commission field is required"/>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default TypeFranchisesConsidering;
