import React, {Component} from 'react';
import {Field, FormSpy} from 'react-final-form';
import {FileInput, Intent, TextArea} from "@blueprintjs/core";
import {Error, ErrorMax, ErrorMin, required, SelectAdapter} from "../CommonFunction";
import {ssoInstance} from "gg-react-utilities";
import {OnChange} from "react-final-form-listeners";
import InvestmentTimePeriod from "../staticData/InvestmentTimePeriod";
import MinInvestments from "../staticData/MinArea";
import Config from "../../core/Config";
import moment from "moment";

const RelatedSelectiOnChanges = ({field, set}) => (
    <Field name={set} subscription={{value: null}}>
        {({input: {onChange}}) => (
            <OnChange name={field}>
                {(value, previous) => {
                    if (value !== previous) {
                        onChange(undefined);
                    }
                }}
            </OnChange>
        )}
    </Field>
);

export default class InvestmentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category_list: [],
            category_list_status: false,
            Investment_period: [],
            Investment_list_status: false,
            Minimum_investment: [],
            Minimum_list_status: false,
            maximum_investment: [],
            maximum_list_status: false
        }
    }

    componentWillMount() {
        this.setCategory();
        this.getMinInvestment();
        this.getMaxInvestment();
    }

    setCategory = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/category`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.category_id,
                            label: item.category_name
                        });
                    });
                    this.setState({category_list: result, category_list_status: true})
                }
            })
            .catch((errors) => {
            });
    };

    getMinInvestment = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/min-investment`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.price,
                            label: item.static_price
                        });
                    });
                    this.setState({Minimum_investment: result, Minimum_list_status: true})
                }
            })
            .catch((errors) => {
            });
    };

    getMaxInvestment = () => {
        ssoInstance.get(`${Config.API_URL}${Config.API_PREFIX}/max-investment`)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    let result = [];
                    sources.data.map((item) => {
                        return result.push({
                            value: item.price,
                            label: item.static_price
                        });
                    });
                    this.setState({maximum_investment: result, maximum_list_status: true})
                }
            })
            .catch((errors) => {
            });
    };

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="industry_type">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"industry_type"}
                                                    options={this.state.category_list}
                                                    placeholder="Industry Type *"
                                                    multi={false}
                                                    isSearch={false}
                                                    validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setSubCategoryByCategoryId}

                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('category_id') ?*/}
                                                {/*        form.getFieldState('category_id').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.category_list_status === false ?*/}
                                                {/*                this.setSubCategoryByCategoryId({value: form.getFieldState('category_id').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                            <RelatedSelectiOnChanges field="category_id" set="sub_category_id"/>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            <FormSpy
                                subscription={{values: true}}
                                render={({form}) => (
                                    <Field name="investment_time_frame">
                                        {({input}) => (
                                            <div>
                                                <SelectAdapter
                                                    name={"investment_time_frame"}
                                                    options={InvestmentTimePeriod}
                                                    placeholder="Time Period of Invesment *"
                                                    multi={false}
                                                    isSearch={true}
                                                    // validate={true}
                                                    msg=""
                                                    handleSelectOnChange={this.setStateByCountryId}
                                                    {...input}
                                                />
                                                {/*{*/}
                                                {/*    form.getFieldState('Investment_period') ?*/}
                                                {/*        form.getFieldState('Investment_period').value !== undefined*/}
                                                {/*            ?*/}
                                                {/*            this.state.Investment_period_status === false ?*/}
                                                {/*                this.setStateByInvestment_period({value: form.getFieldState('Investment_period').value})*/}
                                                {/*                : ""*/}
                                                {/*            : ""*/}
                                                {/*        : ""*/}
                                                {/*}*/}
                                            </div>
                                        )}
                                    </Field>
                                )}
                            />
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            {/*<FormSpy*/}
                            {/*    subscription={{values: true}}*/}
                            {/*    render={({form}) => (*/}
                            {/*        <Field name="min_investment">*/}
                            {/*            {({input}) => (*/}
                            {/*                <div>*/}
                            {/*                    <SelectAdapter*/}
                            {/*                        name={"min_investment"}*/}
                            {/*                        options={this.state.Minimum_investment}*/}
                            {/*                        placeholder="Minimum investment *"*/}
                            {/*                        multi={false}*/}
                            {/*                        isSearch={true}*/}
                            {/*                        // validate={true}*/}
                            {/*                        msg=""*/}
                            {/*                        validate={(value, allValues) => (*/}
                            {/*                            value ? (parseInt(allValues.min_investment) >= parseInt(value)) ? undefined : 'Min' : 'Required'*/}
                            {/*                        )}*/}
                            {/*                        {...input}*/}
                            {/*                    />*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </Field>*/}
                            {/*    )}*/}
                            {/*/>*/}

                            <Field
                                name="min_investment"
                                component="input"
                                placeholder="Minimum investment *"
                                className="bp3-input"
                                validate={(value, allValues) => (
                                    value ? (parseInt(allValues.max_investment) >= parseInt(value)) ? undefined : 'Min' : 'Required'
                                )}
                                type="number"
                                min="0"
                                step={1}

                            />
                            <Error name="min_investment"/>
                            <ErrorMin name="min_investment" msg="Min is less then max investment"/>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-2">
                        <div className="bp3-input-group">
                            {/*<FormSpy*/}
                            {/*    subscription={{values: true}}*/}
                            {/*    render={({form}) => (*/}
                            {/*        <Field name="max_investment">*/}
                            {/*            {({input}) => (*/}
                            {/*                <div>*/}
                            {/*                    <SelectAdapter*/}
                            {/*                        name={"Maxmimum Investment"}*/}
                            {/*                        options={this.state.maximum_investment}*/}
                            {/*                        placeholder="Maxmimum investment *"*/}
                            {/*                        multi={false}*/}
                            {/*                        isSearch={true}*/}
                            {/*                        validate={true}*/}
                            {/*                        msg=""*/}
                            {/*                        validate={(value, allValues) => (*/}
                            {/*                            value ? (parseInt(allValues.max_investment) <= parseInt(value)) ? undefined : 'Max' : 'Required'*/}
                            {/*                        )}*/}
                            {/*                        {...input}*/}
                            {/*                    />*/}
                            {/*                    /!*{*!/*/}
                            {/*                    /!*    form.getFieldState('max_investment') ?*!/*/}
                            {/*                    /!*        form.getFieldState('max_investment').value !== undefined*!/*/}
                            {/*                    /!*            ?*!/*/}
                            {/*                    /!*            this.state.maximum_list_status === false ?*!/*/}
                            {/*                    /!*                this.setStateByMaximum_investment({value: form.getFieldState('max_investment').value})*!/*/}
                            {/*                    /!*                : ""*!/*/}
                            {/*                    /!*            : ""*!/*/}
                            {/*                    /!*        : ""*!/*/}
                            {/*                    /!*}*!/*/}
                            {/*                </div>*/}
                            {/*            )}*/}
                            {/*        </Field>*/}
                            {/*    )}*/}
                            {/*/>*/}
                            <Field
                                name="max_investment"
                                component="input"
                                placeholder="Maximum investment *"
                                className="bp3-input"
                                validate={(value, allValues) => (
                                    value ? (parseInt(allValues.min_investment) <= parseInt(value)) ? undefined : 'Max' : 'Required'
                                )}
                                type="number"
                                min="0"
                                step={1}
                            />
                            <Error name="max_investment"/>
                            <ErrorMax name="max_investment" msg="Max is grater then min investment" />
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mt-2">
                        <label className="bp3-label">Interested In Loans <span className="text-danger">*</span></label>
                        <div className="fp-main">
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="interested_loans"
                                    component="input"
                                    type="radio"
                                    value="1"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} Yes
                            </label>
                            <label className="bp3-control bp3-inline bp3-radio">
                                <Field
                                    name="interested_loans"
                                    component="input"
                                    type="radio"
                                    value="0"
                                    validate={required}
                                />
                                <span className="bp3-control-indicator"></span>
                                {' '} No
                            </label>

                            <Error name="interested_loans"/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}