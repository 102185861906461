import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import {Switch} from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import Home from "../home"
import MyAccount from "../MyAccount"
import FranchiseDetail from "../franchisedetails";
import InvestorRegister from "../investorregister";
import {Notfound, Error, Maintance} from "gg-react-utilities";
import Config from "../core/Config";
import SignUp from "../FranchiseSignup/index";
import FranchiseEdit from "../FranchiseEdit";

export default class Root extends Component {
    render() {
        if (Config.IS_MAINTENANCE_MODE) {
            return (
                <Maintance/>
            );
        }
        return (
            <Switch>
                <PrivateRoute exact path="/" component={Home}/>
                <PrivateRoute exact path="/franchise/:slug" component={FranchiseDetail}/>
                <PrivateRoute exact path="/my-account" component={MyAccount}/>
                <PrivateRoute exact path="/sign-up" component={SignUp}/>
                <PrivateRoute exact path="/sign-up/:slug" component={FranchiseEdit}/>
                <PrivateRoute exact path="/investor/register" component={InvestorRegister} />
            </Switch>
        );
    }
}
