import React from "react";
import {Field} from "react-final-form";
import _, {find, map} from "lodash";
import Select from "react-select";
import {ssoInstance} from "gg-react-utilities";
import Config from "../core/Config";
import {Intent, Position, Toaster} from "@blueprintjs/core";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const onSubmit = async values => {
    let formData = new FormData();
    _.forEach(values, function (value, key) {
        if ("looking_partners_list" === key) {
            formData.append(key, JSON.stringify(value));
        } else if ("brand_logo" !== key || "cover_photo" !== key) {
            formData.append(key, value);
        }
    });

    if (values.brand_logo && values.brand_logo.length > 0) {
        formData.append("brand_logo", values.brand_logo[0]);
    }

    if (values.cover_photo && values.cover_photo.length > 0) {
        formData.append("cover_photo", values.cover_photo[0]);
    }

    await sleep(300);

    return ssoInstance.post(`${Config.API_URL}${Config.API_PREFIX}/franchise/post`, formData)
        .then((res) => {
            let alertColor = Intent.DANGER;
            if (res.data.status === true) {
                alertColor = Intent.SUCCESS;
            }

            Toaster.create({
                position: Position.TOP,
            }).show({message: res.data.message, intent: alertColor});


            setTimeout(function () {
                window.location.href = "/";
            }, 3000);

        })
        .catch((errors) => {
            if (errors.response.status !== 401) {
                let message = "";
                if (errors.response.data) {
                    message = errors.response.data.message ? errors.response.data.message : "Something went wrong!";
                } else {
                    message = "Something went wrong!";
                }
                Toaster.create({
                    position: Position.TOP,
                }).show({message: message, intent: Intent.DANGER});
            }
        })
};

export const required = value => (value ? undefined : 'Required');

const ErrorMessages = (name, msg = "") => {
    switch (name) {
        case 'looking_franchise':
            return 'Franchise is required';

        case 'looking_partners':
            return 'Partners is required';

        case 'internastionl_franchise':
            return 'International franchise is required';

        case 'looking_expanstion':
            return 'Expansion is required';

        case 'unit_chek_box_1':
            return 'Unit field is required';

        case 'store_wise':
            return 'Type Of Franchise field is required';

        case 'investment':
            return 'Budget field is required';

        case 'brand_fee':
            return 'Franchise/Brand Fee field is required';

        case 'royalty':
            return 'Royalty/Commission field is required';

        case 'across_india':
            return 'State or city field is required';


        case 'brand_name':
            return 'Company name field is required';

        case 'contact_person_name':
            return 'Contact person name field is required';

        case 'contact_phone_number':
            return 'Contact person number field is required';

        case 'email_id':
            return 'Email field is required';

        case 'street_1':
            return 'Street 1 field is required';

        case 'street_2':
            return 'Street 2 field is required';

        case 'landmark':
            return 'Landmark field is required';

        case 'area':
            return 'Area field is required';

        case 'country_id':
            return 'Country field is required';

        case 'state_id':
            return 'State field is required';

        case 'city_id':
            return 'City field is required';

        case 'pincode_id':
            return 'Pincode field is required';

        case 'website':
            return 'Website field is required';

        case 'no_of_outlets':
            return 'Franchise outlets field is required';

        case 'min_investment':
            return 'Min investment field is required';

        case 'max_investment':
            return 'Max investment field is required';

        case 'min_area':
            return 'Min area field is required';

        case 'max_area':
            return 'Max area field is required';

        case 'category_id':
            return 'Categories field is required';

        case 'sub_category_id':
            return 'Industry categories field is required';

        case 'sub_sub_category_id':
            return 'Industry classification field is required';

        case 'commission':
            return 'Commission field is required';

        case 'date_comm_op':
            return 'Date operations field is required';

        case 'date_comm_fran':
            return 'Date distribution field is required';

        case 'brand_logo':
            return 'Brand logo field is required';

        case 'cover_photo':
            return 'Cover logo field is required';

        case 'detail':
            return 'Detail field is required';


        case 'fd_question_1':
            return 'Expand field is required';

        case 'fd_question_2':
            return 'Exclusive rights unit field is required';

        case 'fd_question_3':
            return 'Performance unit field is required';

        case 'fd_question_4':
            return 'Anticipated percentage field is required';

        case 'fd_question_5':
            return 'Capital franchise field is required';

        case 'pro_question_1':
            return 'Property franchise opportunity field is required';

        case 'pro_question_2':
            return 'Floor area field is required';

        case 'pro_question_3':
            return 'Preferred location field is required';

        case 'fd_question_6':
            return 'Other investment field is required';

        case 'tr_question_1':
            return 'Detailed operating field is required';

        case 'tr_question_2':
            return 'Franchise training field is required';

        case 'tr_question_3':
            return 'Assistance available field is required';

        case 'tr_question_4':
            return 'Assistance to franchise field is required';

        case 'tr_question_5':
            return 'IT Systems included field is required';

        case 'oth_question_1':
            return 'Standard agreement field is required';

        case 'oth_question_2':
            return 'Franchise term for field is required';

        case 'oth_question_3':
            return 'Term renewable for field is required';

        default:
            return msg;
    }
};

export const Error = ({name, msg = ""}) => (
    <Field
        name={name}
        subscribe={{touched: true, error: true}}
        render={({meta: {touched, error}}) =>
            touched && error ? error === "Required" ?
                <span className="text-danger d-block">{ErrorMessages(name, msg)}</span> : null : null
        }
    />
);

export const ErrorMin = ({name, msg = ""}) => (
    <Field
        name={name}
        subscribe={{touched: true, error: true}}
        render={({meta: {touched, error}}) =>
            touched && error ? error === "Min" ? <span className="text-danger d-block">{msg}</span> : null : null
        }
    />
);

export const ErrorMax = ({name, msg = ""}) => (
    <Field
        name={name}
        subscribe={{touched: true, error: true}}
        render={({meta: {touched, error}}) =>
            touched && error ? error === "Max" ? <span className="text-danger d-block">{msg}</span> : null : null
        }
    />
);

export const SelectAdapter = props => {
    const {
        name,
        options,
        placeholder,
        multi = false,
        isSearch = false,
        validate = false,
        msg = "",
        handleSelectOnChange,
        ...rest
    } = props;
    return (
        <React.Fragment>
            <Field
                name={name}
                validate={validate ? required : ''}
                parse={
                    multi ? val => val && map(val, item => item.value) : val => val && val.value
                }
                format={
                    multi ? val => map(val, item => find(options, o => o.value === item)) : val => find(options, o => o.value === val)
                }
                render={({input, meta}) => (
                    <Select
                        {...rest}
                        {...input}
                        options={options}
                        placeholder={placeholder}
                        isMulti={multi}
                        isSearchable={isSearch}
                        onChange={(e) => {
                            input.onChange(e);
                            if (handleSelectOnChange) {
                                handleSelectOnChange(e);
                            }
                        }}
                    />
                )}
            />
            <Error name={name} msg={msg}/>
        </React.Fragment>
    )
};

export const Condition = ({when, is, children}) => (
    <Field name={when} subscription={{value: true}}>
        {({input: {value}}) => (value === is ? children : null)}
    </Field>
);
