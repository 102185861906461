import {connect} from "react-redux";
import MyRatings from "../Components/MyRatings";
import {getRatedFranchiseList} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        ratedFranchiseList: state.AccountInfo.ratedFranchiseList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRatedFranchiseList: (params) => {
            return getRatedFranchiseList(params);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRatings);