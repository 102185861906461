import {combineReducers} from 'redux';
import Home from "./home";
import FranchiseList from "./FranchiseList";
import AccountInfo from "./AccountInfo";
import FranchiseDetailList from "./FranchiseDetailList";

const rootReducers = combineReducers({
    Home,
    AccountInfo,
    FranchiseList,
    FranchiseDetailList

});

export default rootReducers;
