import React, {Component} from "react";
import FranchiseComponent from "./container/FranchiseContainer";

export default class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <FranchiseComponent
                        {...this.props}
                    />
                </div>
            </React.Fragment>
        )
    }
}
