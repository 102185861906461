import React, {Component} from 'react';
import {Field} from 'react-final-form';
import WantToExpand from "../staticData/WantToExpand";
import LongFranchiseTerms from "../staticData/LongFranchiseTerms";
import {Error, required, SelectAdapter} from "../CommonFunction";

class QuestionDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="pd-panel">
                    <div className="pd-title">
                        <h4>Question</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-5">
                            <SelectAdapter
                                name={"fd_question_1"}
                                options={WantToExpand}
                                placeholder="Want to expand *"
                                multi={false}
                                isSearch={false}
                                validate={true}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="fd_question_4"
                                    component="input"
                                    placeholder="Anticipated percentage return on investment *"
                                    className="bp3-input"
                                    type="number"
                                    validate={required}
                                />
                                <Error name="fd_question_4"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Exclusive Territorial Rights To A Unit *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="fd_question_2"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="fd_question_2"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="fd_question_2"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Performance Guarantee To Unit Franchise *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="fd_question_3"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="fd_question_3"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="fd_question_3"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="fd_question_5"
                                    component="input"
                                    placeholder="Likely pay back period of capital for a unit franchise *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="fd_question_5"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Other Investment Requirements *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="fd_question_6"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="fd_question_6"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="fd_question_6"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="pro_question_1"
                                    component="input"
                                    placeholder="Type of property required for this franchise opportunity *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="pro_question_1"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="pro_question_2"
                                    component="input"
                                    placeholder="Floor area requirement in sq ft *"
                                    className="bp3-input"
                                    type="number"
                                    validate={required}
                                />
                                <Error name="pro_question_2"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="bp3-input-group ">
                                <Field
                                    name="pro_question_3"
                                    component="input"
                                    placeholder="Preferred location of unit franchised outlet *"
                                    className="bp3-input"
                                    validate={required}
                                />
                                <Error name="pro_question_3"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Detailed Operating Manuals For Franchise *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_1"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_1"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="tr_question_1"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Where Is Franchise Training Provided? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_2"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_2"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="tr_question_2"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Is Field Assistance Available For Franchise? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_3"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_3"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="tr_question_3"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Will Someone From Head Office Provides Assistance To Franchise
                                In Opening The Franchise? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_4"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_4"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="tr_question_4"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">What IT Systems Do You Presently Have That Will Be Included In
                                The Franchise? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_5"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="tr_question_5"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="tr_question_5"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Do You Have A Standard Franchise Agreement? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="oth_question_1"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="oth_question_1"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="oth_question_1"/>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <SelectAdapter
                                name={"oth_question_2"}
                                options={LongFranchiseTerms}
                                placeholder="How long is the franchise term for? *"
                                multi={false}
                                isSearch={false}
                                validate={true}
                            />
                        </div>

                        <div className="col-md-6 mb-5">
                            <label className="bp3-label">Is The Term Renewable? *</label>
                            <div className="fp_int_main">
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="oth_question_3"
                                        component="input"
                                        type="radio"
                                        value="yes"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} Yes
                                </label>
                                <label className="bp3-control bp3-inline bp3-radio">
                                    <Field
                                        name="oth_question_3"
                                        component="input"
                                        type="radio"
                                        value="no"
                                        validate={required}
                                    />
                                    <span className="bp3-control-indicator"></span>
                                    {' '} No
                                </label>
                                <Error name="oth_question_3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default QuestionDetails;
