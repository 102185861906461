import React, {Component} from 'react';
import Wizard from './Wizard';
import BasicDetails from "./BasicDetails";
import InvestmentDetails from "./InvestmentDetails";
import PropertyDetails from "./PropertyDetails";
import BusinessDetails from "./BusinessDetails";
import JobDetails from "./JobDetails";
import OtherDetails from "./OtherDetails";
import {onSubmit} from "../CommonFunction";

class WizardIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active_wizard: 1
        };
    };

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <Wizard
                    initialValues={{
                        // address: "ahmedabad",
                        // Area_type: "Sq. Ft.",
                        // Business_Type: "IT",
                        // Commercial_Property: "yes",
                        // qualification: "Graduate",
                        // email: "kishor@gmail.com",
                        // Interest_in_loan: "no",
                        // Investment_period: "3 - 6 months",
                        // Job_Details: "no",
                        // Landline: "3216549852",
                        // Master_Franchise: "no",
                        // Max_area: "1000",
                        // // Maximum_investment: 10000,
                        // Min_area: "50",
                        // // Minimum_investment: 10000,
                        // mobile: "9875648251",
                        // investor_name: "kishor",
                        // No_of_Years: "5",
                        // No_of_employee: "25",
                        // occupation: "software developer",
                        // Other_Industry: "llll",
                        // Parking_Space: "no",
                        // Previous_Business: "yes",
                        // Property_use: "Domestic",
                        // Sole_Decision: "no",
                        // category_id: 3,
                        // city_id: 33,
                        // country_id: 101,
                        // dob: "2000-03-03",
                        // looking_franchise: "no",
                        // pincode_id: 1297,
                        // state_id: 4
                    }}
                    onSubmit={onSubmit}
                >
                    <Wizard.Page>
                        <BasicDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <InvestmentDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <PropertyDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <BusinessDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <JobDetails/>
                    </Wizard.Page>

                    <Wizard.Page>
                        <OtherDetails/>
                    </Wizard.Page>
                </Wizard>
            </React.Fragment>
        );
    }
}

export default WizardIndex;
