const ChanelType = {
    'Retailer': 'Retailer',
    'DSA': 'DSA',
    'Reseller': 'Reseller',
    'MLM': 'MLM',
    'Distributor': 'Distributor',
    'C &amp; F': 'C &amp; F',
    'Dealer': 'Dealer',
    'Agent': 'Agent',
};

export default Object.keys(ChanelType).map(value => ({
    value,
    label: ChanelType[value]
}));
